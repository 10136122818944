@import 'src/sources/styles/mixin.scss';
@import 'src/sources/styles/variables.scss';

.fileViewContainer {
  position: relative;
  width: 100%;
  aspect-ratio: 16/9;
  background: var(--fileBackground);
  border-radius: 11px;
  padding: 10px;

  &.fileViewContainerCreateMode {
    .fileNameBlock {
      margin-left: 42px;
    }
  }

  .iconsBlock {
    height: 35%;
    width: 100%;
    @include center;

    > svg {
      width: auto;

      rect {
        fill: var(--boxColor);
      }

      path {
        fill: var(--superpostMediaIconFill);
      }
    }
  }

  .deleteButton {
    @include button(50%);
    @include center;
    width: 32px;
    height: 32px;
    cursor: pointer;
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 5;
    background: var(--mainColorRevert);

    &:hover {
      opacity: 0.5;
    }

    > svg {
      width: 20px;
      height: 20px;

      path {
        fill: var(--mainColor);
      }
    }
  }

  .fileNameBlock {
    @include flex(center);
    @include textStyle(1rem);
    color: var(--textColor);
    margin-bottom: 10px;
    height: 32px;

    > img {
      margin-right: 5px;
    }
  }
}

.imageBlock {
  aspect-ratio: 16/9;
  position: relative;
  background: var(--boxColor);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  overflow: hidden;
  border-radius: 11px;
  -webkit-box-flex: 1;

  flex: 1 1 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.imageBlockMarginBottom {
  margin-bottom: 8px;
}

.imageZoom {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 11px;
  opacity: 0;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
}

.imageZoomOpacity {
  opacity: 1;
}

.imageCover {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
  border-radius: 11px;
}
