@import 'sources/styles/mixin.scss';
@import 'sources/styles/variables.scss';

.volumeContainer {
  margin-left: 12px;
  padding-right: 5px;
  @include flex(center);

  .volumeButton {
    margin-right: 10px;
    @include button(50%);
    @include center;
    width: 16px;
    height: 16px;

    &.volumeButtonWhiteStyle {
      svg {
        path {
          stroke: white;
        }
      }
    }

    svg {
      path {
        stroke: var(--mainColor);
      }
    }
  }

  .sliderContainer {
    position: relative;
    @include center;
  }

  &.volumeContainerVertical {
    position: relative;
    padding-right: 0;
    justify-content: center;

    .volumeButton {
      margin-right: 0;
    }

    .sliderContainer {
      visibility: hidden;
      opacity: 0;
      z-index: 1;
      position: absolute;
      top: -50px;
      transition: all 0.3s;

      &.sliderContainerVisible {
        visibility: visible;
        opacity: 1;
      }
    }

    .backgroundMask {
      position: absolute;
      top: -7px;
      width: 14px;
      height: 54px;
      border-radius: 7px;
      background: var(--backgroundColor);

      &.backgroundMaskWhiteStyle {
        background: #15143350;
      }
    }
  }
}