@import 'src/sources/styles/mixin.scss';
@import 'src/sources/styles/variables.scss';

.sideButton {
  @include center;
  background: var(--boxColor);
  border: 2px solid var(--textColor);
  cursor: pointer;
  height: 32px;
  width: 32px;
  border-radius: 50%;
  margin-right: 5px;

  svg {
    path {
      fill: var(--textColor);
    }

    circle {
      fill: var(--textColor);
    }
  }
}

