@import 'src/sources/styles/mixin.scss';
@import 'src/sources/styles/variables.scss';

//Resize

.createModalBlock {
  display: flex;
  flex-direction: column;
  margin: 0 0 32px 0;
  position: relative;

  overflow-y: auto;
  @include hideScrollbar;

  //-▼-Text area block wrapper
  .editorAreaWrapper {
    position: relative;
  }
}

//-▼-Control buttons block wrapper
.controlBlock {
  display: flex;
  flex-direction: column;
  opacity: 0;
  height: 0;
  overflow: hidden;
  -moz-transition: opacity 0.2s, max-height 0.2s;
  -ms-transition: opacity 0.2s, max-height 0.2s;
  transition: opacity 0.2s, max-height 0.2s;
}

.activeControlBlock {
  //max-height: 139px;
  height: auto;
  opacity: 1;
}

//.activeControlBlockWithCheckbox {
//Check why need
//  max-height: 172px;
//}

//-▼-Additional buttons block wrapper
.additionalButtonsBlock {
  min-height: 32px;
  display: grid;
  grid-template-columns: auto 1fr auto auto;
  //grid-template-areas: 'attachments poweredBy price buttons';
  grid-template-areas: 'attachments poweredBy price';
  align-items: center;
  gap: 0.5rem;

  .attachmentsBlock {
    grid-area: attachments;
  }

  .poweredBy {
    grid-area: poweredBy;
  }

  .getPriceBlock {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex: 1;
    padding: 0;
    grid-area: price;
  }

  //.buttonsBottom {
  //  padding-left: 1rem;
  //  @include flex(center, flex-end);
  //}
}

//-▼-Create post button block
.createButtonBlock {
  &_checkbox {
    padding: 20px 0 8px 0;
  }

  .buttonsCheckboxes {
    @include flex(flex-start);
    flex-direction: column;
  }

  .buttonsBottom {
    padding-top: 1rem;
    @include flex(center, flex-end);
  }
}

//-▼-Connected Lines with next block
.connectedLines {
  position: absolute;
  height: 32px;
  width: 1px;
  border: 1px solid rgba(169, 169, 169, 0.5);
  bottom: -32px;
  left: 50%;
}

.menuBox {
  width: 200px;
  background: var(--backgroundBoxBurgerMenu);
  border-radius: 7px;
  padding: 8px;
  box-sizing: border-box;
}

.symbolsQuantity {
  //position: absolute;
  width: auto;
  //height: 14.5px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;
  //top: -11px;
  right: 0;
  color: var(--textColor);
  @include textStyle(0.6rem);

  .blockQuantityPlus {
    display: flex;
    height: 14.5px;
    align-items: center;
    margin-right: 4px;
    justify-content: space-between;
    width: auto;

    .addBlockBtn {
      visibility: hidden;
      cursor: default;

      height: 14.5px;
      //cursor: pointer;
      z-index: 1000;

      &:hover {
        opacity: 0.7;
      }

      path {
        fill: var(--textColor);
      }

      circle {
        fill: var(--textColor);
      }
    }

    .addBlockBtnVisible {
      visibility: visible;
      cursor: pointer;
    }
  }

  //.blockQuantityPlusNotEmpty {
  //  width: 70.5px;
  //}

  .blockQuantityPlusMaxWidth {
    width: 100%;
  }
}

//For big screen
@media (min-width: $minWidthForBiggerContentSize+px) {
  .createModalBlock {
    .symbolsQuantity {
      height: 17.5px;
      //top: -11px;
      font-size: 0.72rem;

      .blockQuantityPlus {
        height: 17.5px;

        .addBlockBtn {
          height: 17.5px;
        }
      }

      .blockQuantityPlusNotEmpty {
        width: 84.5px;
      }

      .blockQuantityPlusMaxWidth {
        width: 100%;
      }
    }

    .symbolsQuantityMaxWidth {
      width: 100%;
    }

    .symbolsQuantityError {
      color: red;
    }
  }

  .onDeleteIcon {
    margin-top: 1px;
    width: 14px;
    height: 14px;
  }

  .additionalButtonsBlock {
    min-height: 38px;
  }
}

//@media #{$lgDOWN} {
//  .additionalButtonsBlock {
//    grid-template-areas: 'attachments price buttons' 'poweredBy poweredBy poweredBy';
//    grid-template-columns: 1fr auto auto;
//
//    .poweredBy {
//      justify-content: center;
//
//      &_blockchainpost {
//        padding-top: 0.5rem;
//      }
//    }
//  }
//}

@media #{$mdDOWN} {
  .additionalButtonsBlock {
    grid-template-areas: 'attachments price' 'poweredBy poweredBy';
    grid-template-columns: 1fr auto;

    &_freepost {
      grid-template-areas: 'attachments' 'poweredBy poweredBy';
    }

    .getPriceBlock {
      padding: 0;
      justify-content: flex-start;
    }
  }
}

@media #{$smDOWN} {
  .createButtonBlock {
    margin: 14px 0 8px 0;

    .buttonsBottom {
      padding-top: 0;
    }
  }

  .additionalButtonsBlock {
    grid-template-areas: 'attachments' 'price' 'poweredBy';
    grid-template-columns: 1fr;

    &_freepost {
      grid-template-areas: 'attachments' 'poweredBy';
    }

    .getPriceBlock {
      justify-content: space-between;
      margin-top: 12px;
    }
  }
}
