@import 'src/sources/styles/mixin.scss';
@import 'src/sources/styles/variables.scss';

.sideToolbar {
  background: var(--boxColor);
  display: flex;
  position: absolute;
  z-index: 1;
  left: -36px;
  top: 0;
  transition: all 0.2s ease;

  &.sideToolbarOpenEmbedInput {
    width: 100%;
  }

  .sideButton {
    @include center;
    background: var(--boxColor);
    border: 2px solid var(--textColor);
    cursor: pointer;
    height: 32px;
    min-width: 32px;
    width: 32px;
    border-radius: 50%;
    margin-right: 5px;

    svg {
      transition: all 0.2s ease;
      stroke: var(--textColor)
    }

    .embedIcon {
      width: 18px;
      min-width: 18px;
      height: 12px;
      stroke: none;

      path {
        stroke: var(--textColor)
      }
    }

    &.sideButtonOpen {
      border-color: var(--blogpostPlusOpen);

      svg {
        stroke: var(--blogpostPlusOpen);
        transform: rotate(45deg);
      }
    }
  }
}

