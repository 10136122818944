@import 'src/sources/styles/mixin.scss';
@import 'src/sources/styles/variables.scss';

.fileButtonsContainer {
  min-width: 230px;
  width: 100%;
  display: flex;
  align-items: center;
  flex: 1;
  padding: 0;

  svg,
  img {
    cursor: pointer;
    margin: 0 12px 0 0;
    //max-width: 19px;
    //max-height: 19px;

    &:hover {
      opacity: 0.7;
    }

    path {
      fill: var(--textColor);
    }

    circle {
      fill: var(--textColor);
    }
  }

  .view360Icon {
    max-width: 21px;
    min-width: 21px;
  }

  .audioIcon {
    path {
      stroke: var(--textColor);
    }
  }

  .cube3dIcon {
    max-width: 15px;
    min-width: 15px;
  }

  .fileAddIcon {
    path {
      fill: none;
    }
    stroke: var(--textColor);
    max-width: 15px;
    min-width: 15px;
  }

  .spatialVideoIcon {
    max-width: 21px;
    min-width: 21px;

    path {
      stroke: var(--textColor);
    }
  }

  //-▼-Disabled icon
  .disabledIcon {
    opacity: 0.4;
    pointer-events: none;
    cursor: default;
  }
}

//For big screen
@media (min-width: $minWidthForBiggerContentSize+px) {
  .fileButtonsContainer {
    svg {
      margin-right: 15px;
    }

    .imageIcon {
      width: 18px;
      height: 18px;
    }

    .videoIcon {
      width: 21px;
      height: 15px;
    }

    .smileIcon {
      width: 20px;
      height: 20px;
    }

    .gifIcon {
      width: 19px;
      height: 9px;
    }

    .view360Icon {
      max-width: 24px;
      min-width: 24px;
    }

    .audioIcon {
      width: 18px;
      height: 18px;
    }

    .cube3dIcon {
      max-width: 18px;
      min-width: 18px;
    }

    .fileAddIcon {
      max-width: 18px;
      min-width: 18px;
    }
  }
}

@media #{$smDOWN} {
  .fileButtonsContainer {
    padding: 0;
  }
}

.tooltip3dImage {
  display: flex;
  flex-direction: row;
  gap: 0.75rem;
  z-index: 999999;

  span {
    font-weight: 700;
    cursor: pointer;

    &:hover {
      opacity: 0.43;
    }
  }
}
