@import 'sources/styles/mixin.scss';
@import 'sources/styles/variables.scss';


.content {
  width: 100%;
  @include flex(center, flex-start);
  flex-direction: column;
}

.headText {
  @include textStyle(1.5rem);
  color: var(--textErrorColor);
  margin-bottom: 25px;
}

.bodyText {
  @include textStyle(1rem);
  color: var(--textErrorColor);
  margin-bottom: 25px;
  text-align: center;
}

.linksWrap {
  @include flex(center, space-around);
  margin-bottom: 50px;
  width: 300px;
}

//For big screen
@media (min-width: $minWidthForBiggerContentSize+px) {
  .content {
    .headText {
      @include textStyle(1.875rem);
    }

    .bodyText {
      @include textStyle(1.25rem);
    }
  }
}

