@import 'src/sources/styles/mixin.scss';
@import 'src/sources/styles/variables.scss';


.figuresContainer {
  width: 100%;

  .figuresSection {
    width: 100%;
    @include flex(flex-start, center);
    margin-bottom: 1%;

    &.figuresSectionLast {
      margin-bottom: 0;
    }
  }

  .caption {
    width: 100%;
    text-align: center;
    font-size: 0.75em;
    opacity: 0.6;
    position: relative;

    &::before {
      position: absolute;
      content: attr(data-placeholder);
      top: 5px;
      left: 0;
      right: 0;
      font-size: 0.9em;
    }

    &.captionEmpty {
      &::before {
        content: '';
      }
    }

    > div {
      padding: 5px 0;
    }
  }
}

.blockFigure {
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 1%;

  &.blockFigureMaxWidth {
    max-width: 700px;
  }


  img {
    width: 100%;
    box-sizing: border-box;
  }

  &:last-child {
    margin-inline-end: 0;
  }

  .imageContainer {
    display: flex;
  }
}


//figure.md-block-image {
//    // margin: 0;
//    margin: 10px 0;
//    background: #fbfbfb;
//    img {
//        cursor: default;
//        max-width: 100%;
//        border: 1px solid #eee;
//        box-sizing: border-box;
//
//        &.is-selected {
//            box-shadow: 0 0 0 3px #02b875;
//        }
//    }
//
//    figcaption {
//        display: block;
//        font-size: 14px;
//        line-height: 1.4;
//        color: rgba(0,0,0,.6);
//        letter-spacing: 0;
//        font-weight: 300;
//        font-style: normal;
//        text-align: center;
//        padding: 5px 0;
//
//        .public-DraftStyleDefault-block {
//            text-align: center;
//        }
//
//        &.md-block-image-caption--empty {
//          position: relative;
//
//          &::before {
//            position: absolute;
//            content: attr(data-placeholder);
//            left: 0;
//            opacity: 0.5;
//          }
//        }
//    }
//
//    .md-block-image-inner-container {
//        position: relative;
//    }
//    .md-block-image-toolbar-container {
//        position: absolute;
//        top: 0;
//        right: 10px;
//        cursor: pointer;
//    }
//}
