@import 'src/sources/styles/mixin.scss';
@import 'src/sources/styles/variables.scss';

.button {
  position: absolute;
  bottom: 10px;
  left: 10px;
  @include button(50%);
  @include center;
  width: 32px;
  height: 32px;
  cursor: pointer;
  z-index: 1;
  background: var(--mainColorRevert);

  &:hover {
    opacity: 0.5;
  }
  > svg {
    width: 24px;
    height: fit-content;

    path {
      fill: var(--mainColor);
    }
  }
}

.closeIconBox {
  position: absolute;
  left: 10px;
  top: 10px;
  z-index: 2;
}

.controls {
  position: absolute;
  right: 10px;
  bottom: 10px;
  display: flex;
  flex-direction: column;
  z-index: 2;
}

//.pannellumContainer {
//  .pnlm-container {
//    .pnlm-ui {
//      .pnlm-about-msg {
//        display: none !important;
//      }
//
//      .pnlm-load-box {
//        display: none !important;
//      }
//    }
//  }
//}

.reactPhotoSphereViewer {
  width: 100%;
  z-index: 999;

  &_block {
    .controls {
      bottom: 3rem;
    }
  }
}




