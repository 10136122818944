@import 'src/sources/styles/mixin.scss';
@import 'src/sources/styles/variables.scss';

%editorStyle {
  resize: none;
  width: 100%;
  outline: none;
  @include textStyle(1rem);
  background: var(--boxColor);
  color: var(--textColor);
  mix-blend-mode: normal;
  box-sizing: border-box;
  line-height: 1.2;
  border: 2px solid var(--writeBlockTextAreaBorder);
  border-radius: 7px;
}

.editorContainer {
  width: 100%;
  position: relative;
  cursor: text;
  --CustomEditorMinHeight: 'unset';

  &.editorContainerFullSize {
    flex-grow: 1;

    &.typeEditor :global(.public-DraftEditor-content) {
      flex-grow: 1;

    }

    .editorContainerInner {
      height: 100%;
    }
  }

  &.typeEditor {
    @extend %editorStyle;
    margin: 10px 0;
    padding: 20px 20px 20px 56px;
  }

  &.typeEditorEncrypted {
    padding-bottom: 40px;
  }

  &.typeEditorWithoutAvatar {
    padding: 20px;
  }

  &.typePost {
    @include textStyle(0.8rem);
    color: var(--textColor);
    margin: 5px 0;
    line-height: 1.4;
    word-break: break-word;
    white-space: pre-wrap;
  }

  &.typeBlogpostSingle {
    @include textStyle(1.25rem);

    h1 {
      font-size: 1.7em;
    }

    h2 {
      font-size: 1.4em;
    }
    h3 {
      font-size: 1.15em;
    }
  }

  &.disableCopyText {
    //Blur 3 dots(...)
    //-webkit-line-clamp: dont work with display: inline-block
    display: inline-block;

    span,
    div {
      -webkit-user-select: none;
      -webkit-touch-callout: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }
  }

  &.typeTextEncrypted {
    padding-right: 3px;
  }

  &.typeTextSuperpost {
    opacity: 0.6;
    padding: 0 4px;
    filter: blur(4px);
    -webkit-filter: blur(4px);
    color: white;
  }

  &.typeTextSuperpostPublicText {
    padding: 0 2px;
    color: white;
  }

  &.typeTextSuperpostAvailable {
    opacity: 1;
    padding: 0;
    filter: blur(0);
    -webkit-filter: blur(0);
    color: white;
  }

  &.typeTextWhite {
    opacity: 1;
    padding: 0;
    filter: blur(0);
    -webkit-filter: blur(0);
    color: white;
  }

  &.typeEditorBlogPost {
    padding: 17px 0 17px 35px;
    border-radius: 0;
    border: none;
    border-bottom: 1px dashed var(--writeBlockTextAreaBorder);
  }

  &.modePWA {
    margin: 0;
    padding: 20px 0 0 44px;
    border: none;
    border-radius: 0;
  }

  &.modePWABlogpost {
    padding-left: 35px;
  }
}


//EDITOR STYLE
.editorContainer {
  & :global(.public-DraftEditor-content) {
    figure {
      //margin-block-start: 0;
      //margin-block-end: 0;
      //margin-inline-start: 0;
      //margin-inline-end: 0;

      img,
      video {
        width: 100%;
        max-width: 100%;
      }
    }
  }

  &.typeEditor :global(.DraftEditor-root) {
    overflow: auto;

    scrollbar-color: darkgrey slategrey;
    scrollbar-width: none !important;

    &::-webkit-scrollbar {
      width: 0 !important;
    }
  }

  &.typeEditor :global(.public-DraftEditor-content) {
    min-height: 97px;
  }

  &.typeEditorEncrypted :global(.public-DraftEditor-content) {
    min-height: 77px;
  }

  &.typeEditorAutoHeight :global(.public-DraftEditor-content) {
    min-height: unset;
    height: unset;
  }

  &.typeEditorAutoHeightWithMaxHeight :global(.public-DraftEditor-content) {
    min-height: var(--CustomEditorMinHeight);
    height: unset;
    max-height: 97px;
  }

  &.typeEditorBlogPost :global(.DraftEditor-root) {
    padding: 3px;
  }

  &.typeEditor :global(.public-DraftEditorPlaceholder-root) {
    position: absolute;
    opacity: 0.5;
  }

  &.placeholderH1Style :global(.public-DraftEditorPlaceholder-root) {
    font-size: 2rem;
  }
}

.editorContainerInner {
  //Need for tooltip user info (position: relative);
  position: relative;
  width: 100%;
}

.popper {
  > div {
    max-width: calc(min(400px, 98vw));
    background-color: $textColor;
    border-radius: 7px;
  }
}

.popperArrow {
  > div {
    > span::before {
      background-color: $textColor;
    }
  }
}

@media #{$lgDOWN} {
  .editorContainer {
    &.typeBlogpostSingle {
      @include textStyle(1.125rem);
    }
  }
}

@media #{$smDOWN} {
  .editorContainer {
    &.typeEditor {
      padding: 10px 10px 10px 40px;
    }

    &.typeEditorEncrypted {
      padding-bottom: 40px;
    }

    &.typeEditorWithoutAvatar {
      padding: 10px;
    }

    &.typeEditorBlogPost {
      padding: 7px 0 7px 35px;
    }

    &.typeBlogpostSingle {
      @include textStyle(1rem);

      h1 {
        font-size: 1.5em;
      }

      h2 {
        font-size: 1.3em;
      }
      h3 {
        font-size: 1.1em;
      }
    }

    &.modePWA {
      margin: 0;
      padding: 20px 0 0 44px;
    }

    &.modePWABlogpost {
      padding-left: 35px;
    }
  }
}

//For big screen
@media (min-width: $minWidthForBiggerContentSize+px) {
  .editorContainer {
    &.typeEditor {
      @include textStyle(1.2rem);
    }

    &.typePost {
      @include textStyle(1rem);
    }

    &.typeBlogpostSingle {
      @include textStyle(1.25rem);
    }
  }
}


//Maybe need
//.public-DraftEditor-content {
//  .md-block:first-child {
//    margin-top: 0;
//    padding-top: 0;
//  }
//}
//
//.public-DraftStyleDefault-pre {
//  background-color: rgba(0, 0, 0, 0.05);
//  //font-family: 'Inconsolata', 'Menlo', 'Consolas', monospace;
//  font-size: 16px;
//  padding: 20px;
//}
//
//.public-DraftStyleDefault-unorderedListItem,
//.public-DraftStyleDefault-orderedListItem {
//  margin-bottom: 10px;
//}

