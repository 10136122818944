@import 'src/sources/styles/mixin.scss';
@import 'src/sources/styles/variables.scss';

.fileViewer {
  position: relative;
  width: 100%;
  aspect-ratio: 16/9;
  //background: var(--fileBackground);
  border-radius: 11px;
  padding: 10px;
  border: 2px solid var(--writeBlockTextAreaBorder);

  .deleteButton {
    @include button(50%);
    @include center;
    width: 32px;
    height: 32px;
    cursor: pointer;
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 5;
    background: var(--mainColorRevert);

    &:hover {
      opacity: 0.5;
    }

    > svg {
      width: 20px;
      height: 20px;

      path {
        fill: var(--mainColor);
      }
    }
  }

  &.fileViewerContainerCreateMode {
    .fileNameBlock {
      margin-left: 42px;
    }
  }

  .fileNameBlock {
    @include flex(center);
    @include textStyle(1rem);
    color: var(--textColor);
    margin-bottom: 10px;
    height: 32px;

    > img {
      margin-right: 5px;
    }
  }

  [id*='txt'] {
    color: black;
  }

  [id*='pdf-controls'] {
    z-index: 3;
  }

  .docViewer_docx_xlsx {
    [id*='proxy-renderer'] {
      overflow-y: hidden;
    }
  }
}

.buttons {
  position: absolute;
  bottom: 1rem;
  right: 1rem;
}
