@import 'src/sources/styles/mixin.scss';
@import 'src/sources/styles/variables.scss';

.button {
  position: absolute;
  bottom: 10px;
  left: 10px;
  @include button(50%);
  @include center;
  width: 32px;
  height: 32px;
  cursor: pointer;
  z-index: 1;
  background: var(--mainColorRevert);

  &:hover {
    opacity: 0.5;
  }

  > svg {
    width: 18px;
    min-width: 18px;
    height: fit-content;

    path {
      fill: var(--mainColor);
    }
  }
}





