@import 'src/sources/styles/mixin';
@import 'src/sources/styles/variables';

.userInfoContainer {
  @include flex(flext-start, center);
  flex-direction: column;

  .userInfo {
    @include flex(center, flex-start);
    overflow: hidden;

    .avatar {
      object-fit: cover;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      margin-right: 8px;
    }

    .userNameBox {
      line-height: 1.2;
      //margin-right: 10px;
      overflow: hidden;
      margin-top: auto;
      margin-bottom: auto;

      .userName {
        @include textStyle(0.9rem, bold);
        overflow: hidden;
        text-overflow: ellipsis;
        overflow-wrap: normal;
        word-break: initial;
      }

      .nickname {
        @include textStyle(0.9rem);
        mix-blend-mode: normal;
        opacity: 0.43;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }

  .userInfoActivity {
    @include textStyle(0.8rem, bold);
    .backedBy {
      @include flex(center, flex-start);
      margin: 5px 0;

      .backedByIcon {
        margin-left: 4px;
        width: 12px;
        min-width: 12px;
        height: 12px;
      }
    }

    .followers {
      @include flex(center, flex-start);
    }
  }
}

//For big screen
@media (min-width: $minWidthForBiggerContentSize+px) {
  .userInfoContainer {
    .userInfo {
      .avatar {
        width: 48px;
        height: 48px;
      }

      .userNameBox {
        line-height: 1.2;

        .userName {
          @include textStyle(1.1rem, bold);
        }

        .nickname {
          @include textStyle(1.1rem);
        }
      }
    }

    .userInfoActivity {
      @include textStyle(0.95rem, bold);
      .backedBy {
        .backedByIcon {
          width: 14px;
          min-width: 14px;
          height: 14px;
        }
      }
    }
  }
}
