@import 'src/sources/styles/mixin.scss';
@import 'src/sources/styles/variables.scss';

//this
@keyframes pop-upwards {
  0% {
    transform: matrix(0.97, 0, 0, 1, 0, 12);
    opacity: 0;
  }
  20% {
    transform: matrix(0.99, 0, 0, 1, 0, 2);
    opacity: 0.7;
  }
  40% {
    transform: matrix(1, 0, 0, 1, 0, -1);
    opacity: 1;
  }
  70% {
    transform: matrix(1, 0, 0, 1, 0, 0);
    opacity: 1;
  }
  100% {
    transform: matrix(1, 0, 0, 1, 0, 0);
    opacity: 1;
  }
}

@keyframes pop-downwards {
  0% {
    transform: matrix(0.97, 0, 0, 1, 0, -12);
    opacity: 0;
  }
  20% {
    transform: matrix(0.99, 0, 0, 1, 0, -2);
    opacity: 0.7;
  }
  40% {
    transform: matrix(1, 0, 0, 1, 0, 1);
    opacity: 1;
  }
  70% {
    transform: matrix(1, 0, 0, 1, 0, 0);
    opacity: 1;
  }
  100% {
    transform: matrix(1, 0, 0, 1, 0, 0);
    opacity: 1;
  }
}

$toolbar-color: #323845;

//.md-editor-toolbar {
.toolbar {
  z-index: 100;
  @include center;
  height: 45px;
  width: fit-content;
  background: $toolbar-color;
  color: #fff;
  cursor: auto;
  border-radius: 5px;
  position: absolute;
  transition: all 0.1s ease;
  visibility: hidden;
  box-shadow: 0 1px 3px 0 #747171;

  // transition: top 75ms ease-out,left 75ms ease-out;

  //&.md-editor-toolbar--isopen {
  &.toolbarOpen {
    visibility: visible;
    // transition: top 75ms ease-out,left 75ms ease-out;
    // animation: pop-upwards 180ms forwards linear;
  }

  &.toolbarLinkInput {
    // display:block;
    animation: pop-downwards 200ms forwards linear;
    flex-direction: column;
    height: 75px;

    .toolbarLinkInputCheckbox {
      align-self: flex-start;
    }

    .toolbarLinkCheckButton {
      @include flex(center, space-between);
      flex-direction: row;
      gap: 0.5rem;
      width: 100%;
      padding-left: 1.25rem;

      &_button {
        path {
          fill: white;
        }
      }
    }
  }

  &::after {
    content: '';
    position: absolute;
    bottom: -5px;
    left: 50%;
    margin-left: -5px;
    border-top: 5px solid $toolbar-color;
    border-right: 5px solid transparent;
    border-left: 5px solid transparent;
  }

  //.md-url-input {
  .urlInput {
    box-sizing: border-box;
    border-radius: 2px;
    display: block;
    width: 100%;
    padding: 5px 20px 5px 5px;
    border: none;
    background: $toolbar-color;
    color: #fff;
    @include textStyle(0.9rem);

    &::placeholder {
      color: white;
      opacity: 0.48;
    }

    &:focus {
      outline: none;
      // border-color: #50FF4D;
      // background-color: #eee;
    }
  }

  //.md-url-input-close {
  .urlInputClose {
    position: absolute;
    right: 8px;
    cursor: pointer;
    stroke: white;
    width: 20px;
    height: 20px;
    min-width: 20px;

    &:hover {
      opacity: 0.6;
    }
  }
}

//.md-editor-toolbar-edit-link {
.editLink {
  justify-content: space-between;
  padding: 0 8px 0 15px;
  width: 320px;
  @include textStyle(0.9rem);

  > a {
    color: #fff;
    text-decoration: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &::after {
    bottom: initial;
    top: -5px;
    transform: rotate(180deg);
  }

  .buttonsBox {
    display: flex;
    flex-wrap: nowrap;

    button {
      @include textStyle(0.9rem);
      background: none;
      border: none;
      color: #fff;
      float: right;
      display: inline-block;
      padding: 0 8px;
      cursor: pointer;

      //&.md-editor-toolbar-edit-button {
      &.editButton {
        border-right: 1px solid #fff;
      }
    }
  }
}

//.md-RichEditor-controls {
.controls {
  @include center;
  @include textStyle(1rem);
  padding: 0 8px;
  border-right: 1px solid #555;
  position: relative;

  //&.md-RichEditor-show-link-input {
  &.showLinkInput {
    width: 100%;
    padding: 6px 10px;
  }

  &:last-child {
    border-right: none;
  }

  .linkIcon {
    width: 13px;
    height: 13px;
    min-width: 13px;
  }

  .quotesIcon {
    width: 18px;
    height: 18px;
    min-width: 18px;
  }
}

//.md-RichEditor-styleButton {
.styleButton {
  width: 30px;
  height: 30px;
  min-width: 30px;
  @include center;
  color: white;
  cursor: pointer;

  &:hover {
    color: yellow;
  }

  &:last-child {
    margin-right: 0;
  }

  &.styleButtonBold {
    font-weight: bold;
  }

  &.styleButtonItalic {
    font-style: italic;
  }

  &.styleButtonUnderline {
    text-decoration: underline;
  }

  &.styleButtonStrikethrough {
    text-decoration: line-through;
  }
}

//.md-RichEditor-activeButton {
.styleButtonActive {
  color: #50ff4d;
  background: #242933;

  svg path {
    stroke: #50ff4d;
  }

  &:hover {
    color: #08c;

    svg path {
      stroke: #08c;
    }
  }
}

//.md-RichEditor-controls-block {
.controlsBlock {
  .styleButton:first-child {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }
}
