@import 'src/sources/styles/mixin.scss';
@import 'src/sources/styles/variables.scss';

.snackbar {
  width: 97%;

  .cookieMessage {
    @include textStyle(0.875rem);
  }

  .cookie {
    width: 15px;
    height: 15px;
  }

  .contentRoot {
    justify-content: center;
    background: lavender;
    color: black;

    .btnBox {
      button {
        @include textStyle(0.75rem);
      }

      button:first-child {
        margin-right: 10px;
      }
    }

    .message {
      > div {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;

        img {
          margin-top: 2px;
          margin-right: 10px;
          align-self: flex-start;
        }
      }
    }
  }
}

//For big screen
//@media (min-width: $minWidthForBiggerContentSize+px) {
//  .snackbar {
//
//    .cookieMessage {
//      @include textStyle(1.05rem);
//    }
//
//    .cookie {
//      width: 18px;
//      height: 18px;
//    }
//
//    .contentRoot {
//      .btnBox {
//        button {
//          font-size: 0.9rem;
//        }
//      }
//
//      .message {
//        > div {
//          line-height: 1.3;
//        }
//      }
//    }
//  }
//}

@media (max-width: 700px) {
  .snackbar {
    .contentRoot {
      flex-direction: column;
      align-items: center;

      .action {
        margin-left: 0;
        margin-right: 0;
        padding-left: 0;
      }
    }
  }
}

@media (max-width: 520px) {
  .snackbar {
    .contentRoot {
      .message {
        > div {
          > div {
            max-width: 345px;
          }
        }
      }
    }
  }
}

@media (max-width: 420px) {
  .snackbar {
    .contentRoot {
      .message {
        > div {
          > div {
            max-width: 240px;
          }
        }
      }
    }
  }
}

@media #{$smDOWN} {
  .snackbar {
    width: auto;
  }
}

