@mixin textStyle($size: 1rem, $weight: 400) {
  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: $weight;
  font-size: $size;
}

@mixin flex($align: flex-start, $justify: flex-start) {
  display: flex;
  align-items: $align;
  justify-content: $justify;
}

//Resize
@mixin nickNameText($size: 0.8rem, $weight: bold, $color: var(--textColor)) {
  @include textStyle($size, $weight);
  color: $color;
  opacity: 0.43;
}

@mixin boxStyleFAQ($color: var(--boxColor)) {
  background: $color;
  box-shadow: 0px 7px 25px rgba(0, 0, 0, 0.0496886);
  border-radius: 11px;
}

//Resize
@mixin paymentLinkStyle() {
  @include textStyle(0.6rem);
  text-decoration-line: underline;
  color: var(--textColor);
  opacity: 0.6;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 108px;
}

@mixin scaleAnimation($name, $scaleIn: 1, $scaleOut: 0.9, $speed: 0.25s) {
  animation: #{$name} $speed forwards;

  @keyframes #{$name} {
    from {
      transform: scale($scaleIn);
    }
    to {
      transform: scale($scaleOut);
    }
  }
}

@mixin center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin button($radius: 0px) {
  outline: none;
  border: none;
  background: transparent;
  cursor: pointer;
  border-radius: $radius;
}

@mixin lineClamp($lineClamp: 1) {
  width: 100%;
  width: -moz-available; /* WebKit-based browsers will ignore this. */
  width: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
  width: fill-available;
  display: inline-block;
  overflow: hidden;
  word-break: break-all;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $lineClamp;
  -webkit-box-orient: vertical;
}

@mixin deletedUserStyle() {
  filter: grayscale(0.5) contrast(0.75) brightness(1.5);
}

@mixin hideScrollbar() {
  scrollbar-width: none !important;

  &::-webkit-scrollbar {
    width: 0 !important;
  }
}

@mixin thinScrollbar() {
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    height: 1px !important;
    width: 1px !important;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 0px rgba(46, 43, 99, 0.3) !important;
  }

  &::-webkit-scrollbar-thumb {
    outline: 0px solid slategrey !important;
  }
}

@mixin heartBeat($name, $speed: 0.25s) {
  animation: #{$name} $speed forwards;

  @keyframes #{$name} {
    0% {
      transform: scale(1);
    }
    30% {
      transform: scale(1.4);
    }
    70% {
      transform: scale(1);
    }
  }
}

@mixin applyWithTheme($property, $lightValue, $darkValue) {
  :root[data-theme='light'] & {
    #{$property}: $lightValue;
  }
  :root[data-theme='dark'] & {
    #{$property}: $darkValue;
  }
}
