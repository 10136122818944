@import 'src/sources/styles/mixin.scss';
@import 'src/sources/styles/variables.scss';

.popper {
  //margin: 5px 0 !important;
  > div {
    background-color: $textColor;

    font-size: 0.8rem;
  }
}

.popperArrow {
  > div {
    > span::before {
      background-color: $textColor;
    }
  }
}
