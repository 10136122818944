@import 'src/sources/styles/mixin.scss';
@import 'src/sources/styles/variables.scss';

.box {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1001;
  background: rgba(22, 20, 50, 0.49);
  backdrop-filter: blur(8.15485px);
  @include flex(center, center);

  &.boxHidden {
    visibility: hidden;
    opacity: 0;
    transition: all 0.7s;
  }

  &.boxVisible {
    visibility: visible;
    opacity: 1;
  }

  &.withoutBlurBox {
    background: none;
    backdrop-filter: none;
  }

  &.withoutCloseButton360 {
    cursor: default;
  }

  .content {
    position: relative;
    max-width: 1000px;
    max-height: 1000px;
    width: 90vw;
    height: 85vh;
    background: var(--boxColor);
    @include flex(center, center);
    flex-direction: column;
  }

  .close {
    z-index: 1000;
    width: 20px;
    height: 20px;
    top: 15px;
    left: 15px;
    position: absolute;
    stroke: darkgray;
    cursor: pointer;

    &:hover {
      opacity: 0.5;
    }
  }

  .close3D {
    cursor: pointer;
    z-index: 1000;
    position: absolute;
    top: 10px;
    left: 10px;
  }

  .close360Box {
    position: absolute;
    top: 4px;
    right: 3px;
    @include flex(center, center);
    width: 26px;
    height: 26px;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.4);
    border-radius: 3px;
    cursor: pointer;
    z-index: 2;

    svg {
      stroke: black;
      width: 20px;
      height: 20px;
    }

    &:hover {
      svg {
        stroke: red;
      }
    }
  }
}

