@import 'src/sources/styles/mixin.scss';
@import 'src/sources/styles/variables.scss';

.block {
  margin: 0 0 20px;

  &:last-child {
    margin-bottom: 0;
  }
}
