@import 'src/sources/styles/mixin.scss';
@import 'src/sources/styles/variables.scss';

.currencyEntry {
  .rate {
    width: max-content;
    padding-top: 0.5rem;
    @include textStyle(0.9rem, 700);
  }
}
