@import 'src/sources/styles/mixin';
@import 'src/sources/styles/variables';

.postOnTwitterCheckboxContainer {
  .postOnTwitterCheckbox {
    width: fit-content;
    @include flex(center);
    @include textStyle(0.8rem, normal);
    color: var(--textColor);

    &-checkbox {
      path {
        color: var(--textColor);
      }

      &_disabled {
        path {
          color: #555555;
          opacity: 0.43;
        }
      }
    }

    svg {
      font-size: 1.2rem;
    }

    > span {
      @include center;
      padding: 5px;
      @include textStyle(0.8rem);
      color: var(--textColor) !important;
    }
  }

  .warning {
    &-title {
      margin: 0;
      @include textStyle(0.9rem);
      color: var(--textColor);
      opacity: 0.43;
    }

    ul {
      padding-left: 1rem;
      margin-top: 0.25rem;

      li {
        @include textStyle(0.8rem);
        color: var(--textColor);
        opacity: 0.43;
      }
    }
  }
}
