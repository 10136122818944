@import 'src/sources/styles/mixin.scss';
@import 'src/sources/styles/variables.scss';

.box {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1001;

  width: 100%;
  height: 100%;
  background: rgba(22, 20, 50, 0.49);
  backdrop-filter: blur(8.15485px);
  @include flex(center, center);
  cursor: default;

  &.boxHidden {
    visibility: hidden;
    opacity: 0;
    transition: all 0.7s;
  }

  &.boxVisible {
    visibility: visible;
    opacity: 1;
  }

  &.withoutBlurBox {
    background: none;
    backdrop-filter: none;
  }

  &.boxAdditionalStyle {
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100vh;
  }

  .content {
    position: relative;
    width: 100%;
    box-sizing: border-box;
    background: var(--boxColor);
    border-radius: 17.5px;
    flex-direction: column;
    @include flex(center, center);

    &.contentBig {
      max-width: calc(min(1050px, 95vw));
      padding: 35px 75px;
    }

    &.contentMedium {
      max-width: 750px;
      padding: 35px 75px;
    }

    &.contentSmall {
      max-width: calc(min(550px, 95vw));
      padding: 25px 55px;
    }

    &.contentSuperSmall {
      max-width: calc(min(350px, 95vw));
      padding: 15px 25px;
    }

    //Resize
    &.contentResponsive {
      max-width: calc(min(1480px, 95vw));
      padding: 0;
    }

    &.contentResponsiveMedium {
      max-width: calc(min(768px, 95vw));
      padding: 0;
    }

    &.freeBlock {
      max-width: initial;
      width: auto;
      background: none;
    }
  }

  .close {
    z-index: 1;
    width: 20px;
    height: 20px;
    top: 15px;
    left: 15px;
    position: absolute;
    stroke: darkgray;
    cursor: pointer;
  }

  .close:hover {
    opacity: 0.5;
  }
}

//For big screen
@media (min-width: $minWidthForBiggerContentSize+px) {
  .box {
    .content {
      &.contentResponsive {
        max-width: calc(min(1760px, 95vw));
      }
    }
  }
}

@media #{$IpadDOWN} {
  .box {
    .content {

      &.contentBig {
        padding: 30px 25px;
      }

      &.contentMedium {
        max-width: 500px;
        padding: 30px 25px;
      }
    }

    .zeroPadding {
      padding: 0;
    }
  }
}

@media #{$smDOWN} {
  .box {
    .content {
      &.contentMedium {
        max-width: 350px;
      }

      &.contentSmall {
        padding: 30px 25px;
      }
    }
  }
}

@media #{$xsDOWN} {
  .box {
    .content {
      &.contentMedium {
        max-width: 300px;
      }
    }
  }
}
