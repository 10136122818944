@import 'src/sources/styles/mixin.scss';
@import 'src/sources/styles/variables.scss';

.poweredByAuthencity {
  @include flex(center, flex-start);
  gap: 0.5rem;

  &_w100 {
    @include flex(center, center);
    width: 100%;
  }

  p {
    margin: 0;
    color: #a5a4bc;
    @include textStyle(0.75rem, 700);
    transform: translateY(-2px);
  }

  img {
    width: 75px;
    cursor: pointer;
  }
}
