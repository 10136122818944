@import 'src/sources/styles/mixin.scss';
@import 'src/sources/styles/variables.scss';

.container {
  @include flex();
  flex-direction: column;
  width: 100%;

  .topBox {
    width: 100%;
    @include flex(center, space-between);
    color: var(--textColor);

    .quantitySymbols {
      padding-top: 1px;
      @include textStyle(0.8rem);

      &.quantitySymbolsError {
        color: red;
      }
    }

    .saveMintBox {
      @include center;

      .saveInDraft {
        color: var(--textColor);
        @include button();
        margin-right: 10px;
        @include textStyle(0.9rem);
      }
    }
  }

  //.switchBox {
  //  @include flex(center, flex-end);
  //  width: 100%;
  //  margin-top: 16px;
  //}
}
