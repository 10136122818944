@import 'sources/styles/mixin.scss';
@import 'sources/styles/variables.scss';

.priceContainer {
  padding: 0 8px;
  margin-bottom: 10px;
  width: 100%;
  @include flex(center, flex-end);

  .title {
    @include textStyle(0.85rem, bold);
    line-height: 1;
    color: var(--textColor);
  }

  .inputBlock {
    margin-left: 15px;
    position: relative;

    .input {
      width: 104px;
      height: 32px;
      text-align: end;
      background-color: var(--boxColor_2);
      border-radius: 11px;
      padding: 0 28px 0 7px;
      outline: none;
      border: none;
      @include textStyle(0.7rem);
      line-height: 1.2;
      color: var(--textColor);
      mix-blend-mode: normal;
      box-sizing: border-box;
      -moz-appearance: textfield;

      &.modalInputDisabled {
        background: var(--inputBackDisabled);
      }
    }

    .input::-webkit-outer-spin-button,
    .input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    svg {
      width: 18px;
      height: 18px;
      top: 20%;
      right: 7px;
      position: absolute;
    }
  }
}

//For big screen
@media (min-width: $minWidthForBiggerContentSize+px) {
  .priceContainer {
    .title {
      font-size: 1rem;
    }

    .inputBlock {
      .input {
        width: 130px;
        height: 36px;
        padding: 0 35px 0 9px;
        @include textStyle(0.85rem);
      }

      svg {
        width: 22px;
        height: 22px;
      }
    }
  }
}
