@import 'src/sources/styles/mixin.scss';
@import 'src/sources/styles/variables.scss';

.createPostModalWrapper {
  position: relative;
  width: 100%;
  max-height: calc(min(740px, 90vh));
  padding: 15px 28px 20px;
  //overflow: auto;
  //overflow-x: hidden;
  overflow-y: auto;
  overflow-x: hidden;
  overscroll-behavior: contain;

  scrollbar-color: darkgrey slategrey;
  scrollbar-width: none !important;
  &::-webkit-scrollbar {
    width: 0 !important;
  }

  &.createPostModalWrapperFullSize {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    width: 100%;
    max-width: 1000px;
    max-height: 100%;
  }

  &.createPostModalWidget {
    padding: 0;
  }

  .postTypeSwitch {
    padding: 0 8px;
    @include flex(center, flex-end);
    margin-bottom: 15px;

    &_widget {
      padding: 0;
    }
  }

  .blockQuantityPlus {
    flex-grow: 1;
    @include textStyle(0.75rem, 700);
    @include flex(center);
    gap: 0.5rem;

    svg {
      //background-color: #4bcc00;
      background: linear-gradient(131.69deg, #1f45c3 6.46%, #0e219b 100%);
      border-radius: 50%;
      stroke: white;

      line {
        transform: scale(0.75) translateX(4px) translateY(4px);
      }
    }
  }

  .symbolsQuantityError {
  }
}

.transactionCreateBlock {
  //margin-bottom: 16px;
  padding: 8px;
  width: 100%;
  border: 2px solid var(--writeBlockTextAreaBorder);
  border-radius: 7px;

  &.transactionCreateBlockFullSize {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  &.transactionCreateBlockWidget {
    padding: 0;
  }

  .createPostTopBlock {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;

    .createPostTitle {
      @include textStyle(1.04rem, bold);
      color: var(--textColor);
    }

    .onCloseIcon {
      visibility: hidden;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      //background: rgba(46, 43, 99, 1);
      background: var(--boxColor);
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 1000;

      > svg {
        width: 16px;
        height: 16px;
        stroke: darkgray;
      }
    }

    .onCloseIcon:hover {
      opacity: 0.5;
    }

    .onCloseIconVisible {
      visibility: visible;
    }
  }
}

.transactionCreateBlockOne {
  border: none;

  .createPostTopBlock {
    display: none;
  }
}

//For big screen
@media (min-width: $minWidthForBiggerContentSize+px) {
  .createPostModalWrapper {
    max-height: calc(min(900px, 90vh));
  }
}

@media #{$IpadDOWN} {
  .createPostModalWrapper {
    padding: 15px 12px 20px;
  }
}

@media #{$smDOWN} {
  .createPostModalWrapper {
    padding: 25px 4px 8px;
  }
}

@media #{$xsDOWN} {
  .createPostModalWrapper {
    .postTypeSwitch {
      > div {
        width: 100%;
        min-width: 100%;
      }
    }
  }

  .transactionCreateBlock {
    .createPostTitle {
      text-align: center;
    }
  }
}
