@import 'src/sources/styles/mixin.scss';
@import 'src/sources/styles/variables.scss';

.priceWrapper {
  @include flex(center, center);

  .title {
    margin-right: 4px;
    @include textStyle(0.6rem);
    line-height: 1.1;
    opacity: 0.6;
    color: var(--textColor);
  }

  .priceContainer {
    min-width: 92px;
    padding: 2px 4px;
    box-shadow: 5px 10px 30px rgba(0, 0, 0, 0.05);
    border: 1px solid var(--textColor);
    border-radius: 7px;
    @include flex(center, flex-end);

    .priceBlock {
      margin-right: 6px;
      @include flex(flex-end, center);
      @include textStyle(0.6rem, bold);
      flex-direction: column;
      color: var(--textColor);

      .priceCrypto,
      .priceUSD {
        @include flex(center, flex-end);
      }

      .iconBox {
        @include flex(center, center);
        height: 13px;
        width: 9px;

        svg {
          margin-left: 4px;
        }

        .dollar {
          height: 13px;
          width: 13px;
          min-width: 13px;
          stroke: var(--mainColor);
        }

        .eth {
          path {
            fill: var(--mainColor);
          }

          height: 13px;
          width: 6px;
          min-width: 6px;
          fill: var(--mainColor);
        }

        .matic {
          path {
            fill: var(--mainColor);
          }

          height: 8px;
          width: 9px;
          min-width: 9px;
        }
      }
    }
  }
}

.priceBoxPWA {
  width: 100%;
  text-align: center;
  color: var(--textColor);
  @include textStyle(0.625rem, 700);

  span:first-of-type {
    font-weight: 400;
    opacity: 0.6;
    margin-right: 5px;
  }

  span:last-of-type {
    margin-left: 2px;
    opacity: 0.6;
  }
}

//For big screen
@media (min-width: $minWidthForBiggerContentSize+px) {
  .priceWrapper {
    .title {
      margin-right: 5px;
      @include textStyle(0.75rem);
    }

    .priceContainer {
      min-width: 110px;

      .priceBlock {
        @include textStyle(0.75rem, bold);

        .iconBox {
          height: 16px;
          width: 11px;

          .dollar {
            height: 16px;
            width: 16px;
            min-width: 16px;
          }

          .eth {
            height: 16px;
            width: 8px;
            min-width: 8px;
          }

          .matic {
            height: 10px;
            width: 11px;
            min-width: 11px;
          }
        }
      }
    }
  }
}
