@import 'src/sources/styles/mixin';
@import 'src/sources/styles/variables';

.versionContainer {
  margin-top: auto;
  @include flex(center);
  margin-left: 26px;
  padding-bottom: 24px;

  .logo {
    width: 100px;
  }

  .version {
    color: var(--textColor);
    @include textStyle(0.65rem, bold);
    cursor: pointer;
    padding: 0 5px;
    margin-left: 5px;
    margin-bottom: 2px;
  }
}

