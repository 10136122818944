@import 'sources/styles/mixin.scss';
@import 'sources/styles/variables.scss';

.taggedNickname {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;

  min-height: 19px;
  color: var(--colorCanDecrypt);
  background: var(--backgroundEncryptedMention);
  padding: 0 4px 1px;
  font-weight: bold;
  font-size: 0.8rem;
  border-radius: 4px;
  margin-right: 10px;
}

@media (min-width: 2201px) {
  .taggedNickname {
    min-height: 24px;
    padding: 0 4px 3px;
    font-size: 0.95rem;
  }
}