@import 'src/sources/styles/mixin';
@import 'src/sources/styles/variables';

.adultCheckbox {
  width: fit-content;
  @include flex(center);
  @include textStyle(0.8rem, normal);
  color: var(--textColor);

  svg {
    font-size: 1.2rem;
  }

  > span {
    @include center;
    padding: 5px;
    @include textStyle(0.8rem);
  }
}
