@import 'src/sources/styles/mixin.scss';
@import 'src/sources/styles/variables.scss';

.addFileButtonsBox {
  @include flex(center);

  svg {
    cursor: pointer;
    width: 20px;
    min-width: 20px;
    height: 20px;

    path {
      fill: var(--textColor);
    }

    circle {
      fill: var(--textColor);
    }
  }

  .image360 {
    path:nth-of-type(2) {
      stroke: var(--textColor);
      fill: none;
    }

    circle {
      stroke: var(--textColor);
      fill: none;
    }
  }

  .audio {
    path {
      stroke: var(--textColor);
      fill: none;
    }
  }

  .image3D {
    path {
      stroke: var(--textColor);
      fill: none;
    }
  }
}

@media (max-width: 360px) {
  .addFileButtonsBox {
    > button {
      padding: 7px;
    }
  }
}

@media (max-width: 340px) {
  .addFileButtonsBox {
    > button {
      padding: 6px;
    }
  }
}
