@import 'src/sources/styles/mixin.scss';
@import 'src/sources/styles/variables.scss';


.emoji {
  display: none;
  width: 100vw;
  height: 0;
  //Before
  //max-height: 360px;
  overflow: hidden;
}

.emojiOpen {
  display: block;
  position: initial;
  width: 100vw;
  //Next line in test
  height: 100%;
  //Before
  //height: 50%;
}
