@import 'src/sources/styles/mixin.scss';
@import 'src/sources/styles/variables.scss';

.closeButton {
  position: sticky;
  top: 10px;
  right: 10px;
  @include flex(center, flex-end);
  @include button(5px);
  margin: 10px 10px 0 auto;
  padding: 5px;
  width: fit-content;

  @include textStyle(0.75rem, 400);
  color: #808080;
  text-transform: capitalize;

  .cross {
    stroke: darkgray;
    width: 24px;
    height: 24px;
  }

  &:hover {
    .cross {
      opacity: 0.5;
    }
  }
}

.content {
  @include flex(center);
  flex-direction: column;
  padding: 16px;
  color: white;
  width: 100%;

  .title {
    @include center;
    text-align: center;
    @include textStyle(1.375rem, 700);
    margin-bottom: 20px;
  }

  .subTitle {
    @include center;
    text-align: center;
    @include textStyle(0.875rem, 400);
    margin-bottom: 30px;
  }
}
