@import 'src/sources/styles/mixin.scss';
@import 'src/sources/styles/variables.scss';

.loading {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 99999;
  background: rgba(22, 20, 50, 0.49);
  backdrop-filter: blur(8.15485px);
  @include flex(center, center);
  flex-direction: column;
}

.loaderBox {
  position: relative;
  @include flex(center, center);
  width: 70px;
  height: 70px;
}

.loaderOuter {
  width: 100%;
  height: 100%;
  -webkit-animation: spin 1s linear infinite;
  -moz-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
}

.loaderInner {
  position: absolute;
  width: 50%;
  height: 56%;
}

.textBox {
  @include flex(center, flex-start);
  flex-direction: column;
}

.text {
  margin-top: 10px;
  color: white;
  @include textStyle(1rem, bold);
  @include flex(flex-end, center);
}

.saving {
  margin-top: 5px;
  @include flex(flex-start, center);
  @include textStyle(1rem, bold);
  color: white;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

//For big screen
@media (min-width: $minWidthForBiggerContentSize+px) {
  .text {
    font-size: 1.2rem;
  }

  .saving {
    font-size: 1.2rem;
  }
}
