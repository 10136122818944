@import 'src/sources/styles/mixin.scss';
@import 'src/sources/styles/variables.scss';

.loading {
  position: absolute;
  @include flex(center, center);
  flex-direction: column;
  border-radius: 7px;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 2;
  background: rgba(22, 20, 50, 0.2);
  //backdrop-filter: blur(1px);
}

.loaderBox {
  position: relative;
  @include flex(center, center);
  width: 50px;
  height: 50px;

  .loaderOuter {
    width: 100%;
    height: 100%;
    -webkit-animation: spin 1s linear infinite;
    -moz-animation: spin 1s linear infinite;
    animation: spin 1s linear infinite;
  }

  .loaderInner {
    position: absolute;
    width: 50%;
    height: 56%;
  }
}

.textBox {
  @include flex(flex-end, center);
}

.text {
  margin-top: 10px;
  color: white;
  @include textStyle(1rem, bold);
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
