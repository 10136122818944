@import 'src/sources/styles/mixin.scss';
@import 'src/sources/styles/variables.scss';

.blogpostPreviewModalWrapper {
  width: 100%;
  //max-height: calc(min(740px, 90vh));
  max-height: 90vh;
  padding: 20px 28px;
  overflow-y: auto;
  overflow-x: hidden;
  overscroll-behavior: contain;
  color: var(--textColor);

  scrollbar-color: darkgrey slategrey;
  scrollbar-width: none !important;

  &::-webkit-scrollbar {
    width: 0 !important;
  }

  &.modePWA {
    max-height: unset;
    padding: 0;
  }

  .blogpostPreviewContainer {
    width: 100%;
    @include flex(center);
    flex-direction: column;

    .title {
      @include flex(center, flex-start);
      @include textStyle(1rem, bold);
      margin: 0 0 5px 5px;

      > span {
        color: red;
      }

      .charactersQuantity {
        margin-left: auto;
        padding-right: 5px;
        @include textStyle(0.6rem)
      }

      .charactersQuantityError {
        color: red;
      }
    }

    .previewBox {
      width: 100%;

      .imageBox {
        overflow: hidden;
        border-radius: 7px;
        background: var(--backgroundColor);
        position: relative;
        @include center;
        aspect-ratio: 16/9;
        border: 2px solid var(--writeBlockTextAreaBorder);

        > img {
          width: 100%;
          object-fit: cover;
          aspect-ratio: 16/9;
        }

        .addPreviewBtn {
          cursor: pointer;
          @include textStyle(0.9rem);
          line-height: 1;
          background: rgba(0, 0, 0, .68);
          color: white;
          position: absolute;
          height: 37px;
          padding: 0 16px;
          border: 1px solid rgba(255, 255, 255, .65);
          -webkit-border-radius: 16px;
          border-radius: 16px;

          &:hover {
            opacity: 0.6;
          }
        }
      }
    }

    .titleHashtagsWrapper {
      margin-top: 20px;
      width: 100%;

      .titleBox {
        width: 100%;
        margin-bottom: 20px;
        line-height: 1.2;
        @include textStyle(1.25rem, bold);

        h1 {
          line-height: 1.2;
          @include textStyle(1.25rem, bold);
          margin: 0;
        }
      }

      .hashtagsBox {
        width: 100%;
      }
    }
  }
}

.actionsButtonsBox {
  width: 100%;
  margin-top: 20px;
  @include flex(center, flex-start);
  flex-direction: column;

  .saveInDraft {
    color: var(--textColor);
    @include button();
    padding: 10px;
    margin-top: 10px;
    @include textStyle(0.9rem);

    &:hover {
      opacity: 0.6;
    }
  }
}

//For big screen
@media (min-width: $minWidthForBiggerContentSize+px) {
  .blogpostPreviewModalWrapper {
    max-height: calc(min(900px, 90vh));

    .blogpostPreviewContainer {
      .title {
        @include textStyle(1.2rem, bold);

        .charactersQuantity {
          @include textStyle(0.75rem)
        }
      }
    }
  }
}

@media #{$IpadDOWN} {
  .blogpostPreviewModalWrapper {
    padding: 20px 12px;
  }
}

@media #{$smDOWN} {
  .blogpostPreviewModalWrapper {
    padding: 25px 4px;
  }
}
