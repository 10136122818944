@import 'sources/styles/mixin.scss';
@import 'sources/styles/variables.scss';

.superpostPublicTextContainer {
  margin: 5px 0 10px;
  width: 100%;
  @include flex(flex-start, center);
  flex-direction: column;

  .title {
    margin-bottom: 5px;
    width: 100%;
    @include flex(center, flex-start);
    @include textStyle(0.85rem, bold);
    color: var(--textColor);

    > span {
      color: red;
    }

    .charactersQuantity {
      margin-left: auto;
      padding-right: 5px;
      @include textStyle(0.6rem)
    }

    .charactersQuantityError {
      color: red;
    }
  }

  .editorWrapper {
    width: 100%;
  }
}

//For big screen
@media (min-width: $minWidthForBiggerContentSize+px) {
  .superpostPublicTextContainer {
    .title {
      @include textStyle(1rem, bold);

      .charactersQuantity {
        @include textStyle(0.72rem)
      }
    }

  }
}
