@import 'src/sources/styles/mixin.scss';
@import 'src/sources/styles/variables.scss';

.cropBlock {
  width: 100%;
  //max-height: 510px;
  max-height: 580px;
  height: 80vh;

  .closeModal {
    width: 16px;
    height: 16px;
    top: 12px;
    left: 12px;
    position: absolute;
    stroke: white;
    cursor: pointer;
    z-index: 1000;
    opacity: 0.7;
  }

  .closeModal:hover {
    opacity: 1;
  }

  .cropField {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 180px;
    border-radius: 14px 14px 0 0;

    .cropper {
      background: #c0d1e2;
      border-radius: 14px 14px 0 0;
    }
  }

  .controls {
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 50%;
    transform: translateX(-50%);
    height: 180px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .sliderBox {
      position: relative;
      width: 100%;
      max-height: 40px;

      .sliderTitle {
        position: absolute;
        left: -56px;
        top: 10px;
        @include textStyle(0.8rem);
        color: var(--textColor);
        opacity: 0.75;
      }

      .slider {
        padding: 18px 0px;
      }
    }

    .buttons {
      margin-top: 8px;
      display: flex;
      width: 100%;
      justify-content: space-between;
    }
  }
}

//For big screen
@media (min-width: $minWidthForBiggerContentSize+px) {
  .cropBlock {
    max-height: 655px;

    .closeModal {
      width: 20px;
      height: 20px;
    }

    .cropField {
      bottom: 225px;
    }

    .controls {
      height: 225px;

      .sliderBox {
        max-height: none;

        .sliderTitle {
          left: -70px;
          top: 12px;
          @include textStyle(0.95rem);
        }

        .slider {
          padding: 22px 0;
        }
      }

      .buttons {
        margin-top: 10px;
      }
    }
  }
}

@media #{$smDOWN} {
  .cropBlock {

    .cropField {
      bottom: 240px;
    }

    .controls {
      width: 90%;
      height: 232px;

      .sliderBox {
        max-height: unset;

        .sliderTitle {
          position: static;
        }
      }
    }
  }
}


