@import 'src/sources/styles/mixin';
@import 'src/sources/styles/variables';

.adultBlur {
  position: absolute;
  top: 0;
  left: 0;
  @include center;
  width: 100%;
  height: 100%;
  z-index: 10;
  //background-color: rgba(255, 255, 255, 0.4);
  background-color: rgba(22, 20, 50, 0.49);
  -webkit-backdrop-filter: blur(15px);
  backdrop-filter: blur(15px);
  cursor: pointer;

  .messageBlock {
    width: 100%;
    @include center;
    flex-direction: column;
    @include textStyle(calc(min(0.8rem, 2vw)), bold);
    color: white;

    >div {
      padding: 5px 10px;
      text-align: center;
    }

    div:first-child {
      font-size: calc(min(1rem, 2.5vw));
    }
  }
}