@import 'src/sources/styles/mixin.scss';
@import 'src/sources/styles/variables.scss';

.confirmModal {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: auto;

  .modalTitle {
    @include textStyle(1.04rem, bold);
    color: var(--modalTitleColor);
    text-align: center;
    margin: 0 0 32px 0;
  }

  .buttons {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
}

//For big screen
@media (min-width: $minWidthForBiggerContentSize+px) {
  .confirmModal {
    .modalTitle {
      @include textStyle(1.25rem, bold);
    }
  }
}

@media #{$smDOWN} {
  .confirmModal {
    .buttons {
      flex-direction: column;

      > button {
        margin: 5px 0;
        width: 100% !important;
      }
    }
  }
}
