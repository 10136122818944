@import 'src/sources/styles/mixin.scss';
@import 'src/sources/styles/variables.scss';

%switchImgBox {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 22px;
  height: 22px;
}

.switchTheme {
  margin: 0 15px;
  background: #f7f7f7;
  border-radius: 16px;
  width: 52px;
  height: 26px;
  align-items: center;
  display: flex;
  cursor: pointer;

  .oval {
    height: 22px;
    width: 22px;
    border-radius: 50%;
    background: #dfe4f4;
    margin-left: 3px;
    transition: 0.3s;
  }

  .sun {
    @extend %switchImgBox;
    margin-left: 3px;
    >img {
      width: 20px;
      height: 20px;
    }
  }

  .moon {
    @extend %switchImgBox;
    margin-left: 27px;

    >img {
      height: 17px;
      margin-left: 2px;
    }
  }
}

//Dark Theme Switch
.switchThemeDark {
  background: $darkFon;

  .oval {
    margin-left: 27px;
  }
}

//For big screen
@media (min-width: $minWidthForBiggerContentSize+px) {
  .switchTheme {
    width: 62px;
    height: 32px;

    .oval {
      height: 26px;
      width: 26px;
      margin-left: 4px;
    }

    .sun {
      width: 24px;
      height: 24px;

      margin-left: 5px;

      >img {
        width: 24px;
        height: 24px;
      }
    }

    .moon {
      width: 24px;
      height: 24px;
      margin-left: 34px;

      >img {
        height: 17px;
        margin-left: 2px;
      }
    }
  }

  //Dark Theme Switch
  .switchThemeDark {
    .oval {
      margin-left: 33px;
    }
  }
}


