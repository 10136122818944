@import 'src/sources/styles/mixin.scss';
@import 'src/sources/styles/variables.scss';

.btn {
  @include flex(center, center);
  outline: none;
  border: none;
  cursor: pointer;
  border-radius: 11px;
  box-shadow: 0 7px 25px rgba(0, 0, 0, 0.05);
  mix-blend-mode: normal;

  .imgSvgIcon {
    -webkit-mask-repeat: no-repeat;
    -webkit-mask-size: contain;
  }

  .btnText {
    @include center;
    @include textStyle(0.9rem);
    line-height: 1.1;
    color: var(--textColor);
  }
}

.disabled {
  cursor: default;
  opacity: 0.3;
  pointer-events: none;
}

.btnStyle_1 {
  background: var(--mainColorRevert);

  .imgSvgIcon {
    background: var(--mainColor);
  }

  &:hover {
    background: var(--mainColor);

    .imgSvgIcon {
      background: var(--mainColorRevert);
    }
  }
}

.btnStyle_2 {
  background: var(--boxColor);

  .imgSvgIcon {
    background: var(--mainColor);
  }

  &:hover {
    background: var(--mainColor);

    .imgSvgIcon {
      background: var(--mainColorRevert);
    }
  }
}

.btnStyle_3 {
  background: var(--mainColor);

  .btnText {
    color: var(--mainColorRevert);
  }

  &:hover {
    background: var(--mainColorRevert);

    .btnText {
      color: var(--mainColor);
    }
  }
}

.btnStyle_4 {
  transition: all 0.2s;

  .btnText {
    color: white;
  }

  &:hover {
    background: white;

    .btnText {
      color: #1f45c3;
    }
  }
}

.btnStyle_5 {
  transition: all 0.2s;

  .btnText {
    color: white;
  }

  &:hover {
    background: white !important;

    .btnText {
      color: #1f45c3 !important;
    }
  }
}

.btnStyle_6 {
  transition: all 0.2s;
  background: linear-gradient(131.69deg, #1f45c3 6.46%, #0e219b 100%);

  .btnText {
    color: white;
  }

  &:hover {
    background: white;

    .btnText {
      color: #1f45c3;
    }
  }
}

.btnStyle_7 {
  transition: all 0.2s;
  background: var(--backgroundVideoChat);

  .btnText {
    color: #ff5f5f;
  }

  &:hover {
    background: #ff5f5f;

    .btnText {
      color: white;
    }
  }
}

//Bordered
.btnStyle_8 {
  transition: all 0.2s;
  background-color: transparent;
  border: 1px solid var(--mainColor);

  .btnText {
    color: var(--mainColor);
  }

  &:hover {
    border: 1px solid var(--mainColorRevert);
    background: var(--mainColor);

    .btnText {
      color: var(--mainColorRevert);
    }
  }
}

//Blue hover
.btnStyle_9 {
  //background: linear-gradient(#2E2B63, #2E2B63);
  background: #2e2b63;

  .imgSvgIcon {
    background: white;
  }

  &:hover {
    background: rgba(103, 174, 255, 0.6);

    .imgSvgIcon {
      background: #1f45c3;
    }
  }
}

.btnStyle_10 {
  transition: all 0.2s;
  background: white;

  .btnText {
    color: #1f45c3;
  }

  &:hover {
    background: linear-gradient(131.69deg, #1f45c3 6.46%, #0e219b 100%);

    .btnText {
      color: white;
    }
  }
}

.btnStyle_11 {
  transition: all 0.2s;
  background: white;

  .btnText {
    color: black;
  }

  &:hover {
    background: linear-gradient(131.69deg, #1f45c3 6.46%, #0e219b 100%);

    .btnText {
      color: white;
    }
  }
}

.btnStyle_12 {
  transition: all 0.2s;
  background: white;
  border: 2px solid #cbd5e1;
  border-bottom-width: 4px;

  .btnText {
    color: black;
  }

  &:hover {
    background: #4bcc00;
    border: none;

    .btnText {
      color: white;
    }
  }
}

.btnStyle_13 {
  transition: all 0.2s;
  background: #4bcc00;

  .btnText {
    color: white;
  }

  &:hover {
    background: white;
    border: 2px solid #cbd5e1;
    border-bottom-width: 4px;

    .btnText {
      color: black;
    }
  }
}

//For big screen
@media (min-width: $minWidthForBiggerContentSize+px) {
  .btn {
    .btnText {
      @include textStyle(1.1rem);
    }
  }
}
