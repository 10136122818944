@import 'sources/styles/mixin.scss';
@import 'sources/styles/variables.scss';

.deactivatedUser {
  color: white;

  > div {
    padding: 2px 0 2px 15px;
  }

  .title {
    padding-left: 0;
  }

  .deactivated {
    opacity: 0.6;
  }
}
