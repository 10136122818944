@import 'src/sources/styles/mixin.scss';
@import 'src/sources/styles/variables.scss';

.mediaBlur {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  aspect-ratio: 16/9;
  overflow: hidden;
  border-radius: 15px;
  background-image: url(../../../src/sources/images/superpostBlur.jpeg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  &.superpostBlurVideoShort {
    aspect-ratio: 7/10;
  }

  .iconsBlock {
    height: 27%;
    width: 100%;
    @include center;

    > svg {
      width: auto;

      rect {
        fill: var(--boxColor);
      }

      path {
        fill: var(--superpostMediaIconFill);
      }
    }
  }

  .mediaBlurPost {
    @include flex(center, center);
    flex-direction: column;
    color: white;
    padding: 1rem;

    .item {
      @include textStyle(1rem, bold);
      line-height: 1.2;
      text-align: center;
    }
  }

  &_multiple {
    gap: 0.5rem;

    .mediaBlurPost {
      padding: 0.1rem;
    }
  }
}

@media #{$mdDOWN} {
  .mediaBlur {
    gap: 0.5rem;

    .mediaBlurPost {
      padding: 0.25rem;

      .item {
        font-size: 0.9rem;
      }
    }

    &_multiple {
      gap: 0.15rem;

      .mediaBlurPost {
        padding: 0.1rem;

        .item {
          font-size: 0.75rem;
        }
      }
    }
  }
}
