@import 'src/sources/styles/mixin.scss';
@import 'src/sources/styles/variables.scss';

.addButton,
.editButton {
  margin-top: 12px;
  margin-bottom: 12px;
  color: var(--textColor);
  @include textStyle(1rem, 500);
  text-decoration: underline;
  @include button();

  > b {
    color: red;
  }
}

.audioVideoTitle,
.audioVideoDescription {
  @include textStyle(0.8rem);
  color: var(--textColor);
  line-height: 1.4;
  word-break: break-word;
  white-space: pre-wrap;
}

.container {
  width: 100%;

  .block {
    width: 100%;
    @include flex(flex-start, center);
    flex-direction: column;

    &:first-of-type {
      margin-bottom: 15px;
    }

    .title {
      margin-bottom: 4px;
      width: 100%;
      padding-left: 10px;
      @include flex(center, flex-start);
      @include textStyle(1rem, bold);
      color: var(--textColor);

      > span {
        color: red;
      }

      .charactersQuantity {
        margin-left: auto;
        padding-right: 5px;
        @include textStyle(0.6rem)
      }

      .charactersQuantityError {
        color: red;
      }
    }

    .editorWrapper {
      width: 100%;

      > div {
        border: 2px solid var(--PWAAVTDBorderColor);
        background: var(--PWAAVTDBackground);
      }
    }
  }
}
