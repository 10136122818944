@import 'src/sources/styles/mixin.scss';
@import 'src/sources/styles/variables.scss';

.snackbar {
  width: 95%;
  max-width: 1100px;

  .contentRoot {
    padding: 1.25rem;
    border-radius: 0.75rem;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 1rem;
    background: var(--boxColor);

    .action {
      .btnBox {
        @include flex(center, center);
        flex-direction: row;
        gap: 0.75rem;

        .button {
          width: max-content;
          height: 40px;
          padding: 0.5rem 1rem;
        }
      }
    }

    .message {
      padding: 0;
      color: var(--textColor);
      @include flex(center, center);
      gap: 1.25rem;

      p {
        opacity: 0.67;
        margin: 0;
      }

      .authLogo {
      }
    }

    @media #{$smMUIDOWN} {
      flex-direction: column;
      gap: 0.5rem;
    }
  }
}
