@import 'sources/styles/mixin.scss';
@import 'sources/styles/variables.scss';

.container {
  z-index: 1;
  position: absolute;
  bottom: 10px;
  left: 10px;
  padding: 5px 10px;
  background: #EAECF7;
  color: $textColor;
  border-radius: 7px;
  @include textStyle(0.7rem);
  line-height: 1.2;
  max-width: calc(100% - 20px);
  overflow: hidden;
  text-overflow: ellipsis;

  &.modePWA {
    margin-top: 16px;
    position: unset;
    width: fit-content;
    padding: 6px 12px;
    align-self: center;
  }
}
