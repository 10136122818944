@import 'sources/styles/mixin.scss';
@import 'sources/styles/variables.scss';


.audioPlayerContainer {
  @include flex(center, space-between);
  min-height: 32px;

  &.audioPlayerContainerWhiteStyle {
    .playButton {
      background: white;

      .playIcon {
        path {
          fill: #2E2B63;
        }
      }
    }

    .timeToEnd {
      color: white;
    }
  }

  .playButton {
    margin-right: 12px;
    @include center;
    @include button(50%);
    width: 32px;
    min-width: 32px;
    height: 32px;
    background: var(--mainColor);

    &:hover {
      svg {
        transform:scale(1.2);
      }
    }

    svg {
      transform:scale(1);
    }

    .playIcon {
      margin-left: 3px;
      path {
        fill: var(--mainColorRevert);
      }
    }
  }

  .waveFormContainer {
    width: 100%;
    height: 32px;
    cursor: pointer;

    .overviewContainer {
      //margin-top: -4px;
      //height: 40px;
      margin-top: -1px;
      height: 34px;
      line-height: 0;
    }
  }

  .timeToEnd {
    margin-left: 12px;
    @include textStyle(0.9rem, bold);
    color: var(--textColor);
  }
}