@import 'src/sources/styles/mixin';
@import 'src/sources/styles/variables';

.mention {
  //color: #4a85bb;
  color: rgb(29, 155, 240);
  text-decoration: none;
}

.mentionSuggestions {
  z-index: 1;
  max-width: 100%;
  min-width: 220px;
  //background-color: white;
  //border-top: 1px solid #eee;
  border-top: 1px solid var(--mentionBorderTop);
  background-color: var(--mainColorRevert);
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.3);
  border-radius: 7px;
  cursor: pointer;
  padding-top: 5px;
  padding-bottom: 5px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  transform-origin: 50% 0%;
  transform: scaleY(0);
}

.mentionSuggestionsEntryContainer {
  display: table;
  width: 100%;
}

.mentionSuggestionsEntryContainerLeft,
.mentionSuggestionsEntryContainerRight,
.mentionSuggestionsEntryContainerBacked {
  display: table-cell;
  vertical-align: middle;
}

.mentionSuggestionsEntryContainerRight {
  width: 100%;
  padding-left: 8px;
  padding-right: 8px;
}

.mentionSuggestionsEntryContainerBacked {
  >div {
    display: flex;
    align-items: center;
    @include textStyle(0.88rem, bold);
    >img {
      width: 19px;
      margin-right: 2px;
    }
  }
}

.mentionSuggestionsEntry {
  //padding: 7px 10px 3px 10px;
  padding: 5px 10px 5px 10px;
  transition: background-color 0.4s cubic-bezier(.27,1.27,.48,.56);
}

.mentionSuggestionsEntry:active {
  //background-color: #cce7ff;
  background-color: var(--mentionBackgroundActive);
}

.mentionSuggestionsEntryFocused {
  composes: mentionSuggestionsEntry;
  //background-color: #e6f3ff;
  background-color: var(--mentionBackgroundFocused);
}

.mentionSuggestionsEntryName,
.mentionSuggestionsEntryNickName {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.mentionSuggestionsEntryName {
  margin-bottom: 1px;
  @include textStyle(0.88rem, bold);
  line-height: 1;
  //color: $textColor;
  color: var(--textColor);
}

.mentionSuggestionsEntryNickName {
  @include nickNameText(0.88rem, bold);
  line-height: 1;
}

.mentionSuggestionsEntryAvatar {
  display: block;
  border-radius: 50%;
  width: 30px;
  min-width: 30px;
  height: 30px;
  min-height: 30px;
  object-fit: cover;
}

.mentionSuggestionsEntryAvatarEmpty {
  background: #e9ecf9;
}

//For big screen
@media (min-width: $minWidthForBiggerContentSize+px) {
  .mentionSuggestions {
    min-width: 260px;
  }

  .mentionSuggestionsEntryName {
    @include textStyle(1.05rem, bold);
  }

  .mentionSuggestionsEntryNickName {
    @include nickNameText(1.05rem, bold);
  }

  .mentionSuggestionsEntryContainerBacked {
    >div {
      @include textStyle(1.05rem, bold);
      >img {
        width: 21px;
      }
    }
  }

  .mentionSuggestionsEntryAvatar {
    width: 36px;
    min-width: 36px;
    height: 36px;
    min-height: 36px;
  }
}
