@import 'src/sources/styles/mixin.scss';
@import 'src/sources/styles/variables.scss';

.body {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: $white;
  mix-blend-mode: normal;
  outline: none;
  border: none;
  cursor: pointer;
  @include flex(center, center);
  box-sizing: border-box;
  box-shadow: 0px 7px 25px rgba(0, 0, 0, 0.0496886);

  &.bodyBorder {
    border: 1.5px solid var(--mainColor);
  }

  .btnIcon {
    background: $textColor;
    mask-size: contain;
    -webkit-mask-size: contain;
    mask-repeat: no-repeat;
    -webkit-mask-repeat: no-repeat;
    height: 10px;
    width: 9px;
  }
}

.darkBody {
  //background: $textColor !important;
  background: $textColor;

  &.bodyBorder {
    border: 1.5px solid white;
  }

  .btnIcon {
    background: $white;
  }
}

.popper {
  > div {
    background-color: $textColor;
  }
}

.popperArrow {
  > div {
    > span::before {
      background-color: $textColor;
    }
  }
}


//For big screen
@media (min-width: $minWidthForBiggerContentSize+px) {
  .body {
    width: 36px;
    height: 36px;

    .btnIcon {
      height: 12px;
      width: 11px;
    }
  }
}

@media (hover: hover) {
  .body.activeHover:hover {
    background: $textColor !important;

    .btnIcon {
      background: $white !important;
    }
  }
  .darkBody.activeHover:hover {
    background: $white !important;

    .btnIcon {
      background: $textColor !important;
    }
  }
}
