@import 'sources/styles/mixin.scss';
@import 'sources/styles/variables.scss';

.imageBlock {
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  overflow: hidden;
  border-radius: 11px;
  -webkit-box-flex: 1;
  //flex-grow: 1;

  flex: 1 1 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.imageBlockMarginBottom {
  margin-bottom: 8px;
}

.imageZoom {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 11px;
  opacity: 0;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
}

.imageZoomOpacity {
  opacity: 1;
}

.imageCover {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 11px;
}

.button {
  @include button(50%);
  @include center;
  width: 32px;
  height: 32px;
  cursor: pointer;
  position: absolute;
  z-index: 5;
  background: var(--mainColorRevert);

  &:hover {
    opacity: 0.5;
  }

  &.buttonDelete {
    top: 10px;
    left: 10px;

    > svg {
      width: 20px;
      height: 20px;

      path {
        fill: var(--mainColor);
      }
    }
  }

  &.buttonEdit {
    bottom: 10px;
    right: 10px;

    > svg {
      width: 17px;
      height: 17px;

      path {
        fill: var(--mainColorRevert);
      }

      path:first-child {
        fill: var(--mainColor);
      }
    }
  }
}

@media #{$smDOWN} {
  .button {
    &.buttonDelete {
      top: 5px;
      left: 5px;
    }

    &.buttonEdit {
      bottom: 5px;
      right: 5px;
    }

    &.buttonDelete3D {
      top: 10px;
      left: 10px;
    }
  }
}
