@import 'src/sources/styles/mixin.scss';
@import 'src/sources/styles/variables.scss';

.link {
  @include flex(center, center);
  flex-direction: column;
  cursor: pointer;
  text-decoration: none;
  @include textStyle(0.85rem);
  color: var(--textErrorColor);
}

.img {
  height: 40px;
  width: 40px;
  margin-bottom: 10px;
}

//For big screen
@media (min-width: $minWidthForBiggerContentSize+px) {
  .link {
    @include textStyle(1rem);
  }

  .img {
    height: 50px;
    width: 50px;
  }
}