@import 'src/sources/styles/mixin.scss';
@import 'src/sources/styles/variables.scss';

.tooltip {
  color: white;
  > div {
    padding: 2px 0 2px 12px;
  }

  .tooltipTitle {
    padding-left: 0;
  }
}

.activatedUser {
  cursor: pointer;
  margin-left: 4px;
  min-width: 16px;
  width: 16px;
}

.deactivatedUser {
  opacity: 0.4;
}

//For big screen
@media (min-width: $minWidthForBiggerContentSize+px) {
  .activatedUser {
    cursor: pointer;
    margin-left: 4px;
    min-width: 20px;
    width: 20px;
  }
}
