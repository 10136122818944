@import 'src/sources/styles/mixin';
@import 'src/sources/styles/variables';

.container {
  width: 100%;

  .title {
    color: var(--textColor);
    @include textStyle(1.25rem, 700);
  }

  .loginTypes {
    margin-top: 25px;
    width: 100%;

    > button {
      margin-bottom: 15px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .typeIcon {
      width: 24px;
      min-width: 24px;
      height: fit-content;
      margin-right: 8px;

      &.fillIcon {
        path {
          //fill: #1D9BF0;
        }
      }
    }
  }
}
