@import 'src/sources/styles/mixin.scss';
@import 'src/sources/styles/variables.scss';

.mediaIcon {
  margin-bottom: 10px;
  min-width: 19.2px;
  width: 19.2px;
  height: 19.2px;
}


//For big screen
@media (min-width: $minWidthForBiggerContentSize+px) {
  .mediaIcon {
    min-width: 23px;
    width: 23px;
    height: 23px;
  }
}
