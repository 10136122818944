@import 'src/sources/styles/mixin';
@import 'src/sources/styles/variables';

.container {
  @include flex(flext-start, center);

  .logo {
    object-fit: cover;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 8px;
  }

  .currencyInfo {
    @include flex(flex-start, flex-start);
    flex-direction: column;
    overflow: hidden;

    .nameBox {
      line-height: 1.2;
      //margin-right: 10px;
      overflow: hidden;
      margin-top: auto;
      margin-bottom: auto;
      @include flex;
      gap: 0.5rem;

      .name {
        @include textStyle(0.9rem, bold);
        overflow: hidden;
        text-overflow: ellipsis;
        overflow-wrap: normal;
        word-break: initial;
      }

      .code {
        @include textStyle(0.9rem);
        mix-blend-mode: normal;
        opacity: 0.43;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    .rate {
      @include textStyle(0.8rem, bold);
      @include flex(center, flex-start);
      margin: 5px 0;

      .rateIcon {
        margin-left: 4px;
        width: 12px;
        min-width: 12px;
        height: 12px;
      }
    }
  }
}

//For big screen
@media (min-width: $minWidthForBiggerContentSize+px) {
  .currencyInfoContainer {
    .currencyInfo {
      .nameBox {
        line-height: 1.2;

        .name {
          @include textStyle(1.1rem, bold);
        }

        .nickname {
          @include textStyle(1.1rem);
        }
      }
    }

    .currencyInfoActivity {
      @include textStyle(0.95rem, bold);
      .rate {
        .rateIcon {
          width: 14px;
          min-width: 14px;
          height: 14px;
        }
      }
    }
  }
}
