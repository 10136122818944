@import 'src/sources/styles/mixin.scss';
@import 'src/sources/styles/variables.scss';

.switchCurrencyBox {
  position: relative;
  z-index: 0;
  @include flex(center, space-between);
  margin-right: 16px;
  padding: 2px;
  background: var(--boxColor_2);
  width: 52px;
  min-width: 52px;
  height: 26px;
  border-radius: 13px;

  &.modePWA {
    width: 58px;
    min-width: 58px;
    height: 30px;
    border-radius: 16px;
    margin-right: 0;
  }

  &_widget {
    @include applyWithTheme(
      background-color,
      var(--boxColor_2),
      var(--boxColor)
    );
  }

  .switchBoxInner {
    width: 100%;
    height: 100%;
    position: relative;
    @include flex(center);

    .mask {
      z-index: 1;
      position: absolute;
      background: #dfe4f4;
      border-radius: 50%;
      width: 50%;
      height: 100%;
      transition: all 0.3s;
      transform: translateX(0%);

      &.positionRight {
        transform: translateX(100%);
      }
    }

    .btn {
      @include button(50%);
      @include flex(center, center);
      cursor: pointer;
      width: 50%;
      height: 100%;
      z-index: 2;
      transition: all 0.3s;

      > svg {
        width: 100%;
        height: 100%;
        transition: all 0.3s;
      }

      &.inactive {
        opacity: 0.6;

        svg path {
          fill: var(--mainColor);
        }
      }
    }
  }
}

//For big screen
@media (min-width: $minWidthForBiggerContentSize+px) {
  .switchCurrencyBox {
    width: 64px;
    min-width: 64px;
    height: 32px;
  }
}
