@import 'src/sources/styles/mixin.scss';
@import 'src/sources/styles/variables.scss';

.switch {
  z-index: 0;
  @include flex(center, space-between);
  width: 350px;
  min-width: 350px;
  padding: 3px;
  height: 24px;
  border-radius: 16px;
  background: var(--boxColor_2);

  .switchInner {
    @include flex(center, flex-start);
    position: relative;
    width: 100%;
    height: 100%;
    user-select: none;

    .switchBtn {
      z-index: 1;
      position: absolute;
      background: #dfe4f4;
      border-radius: 16px;
      height: 100%;
      transition: all 0.3s;
    }

    .button {
      cursor: pointer;
      color: $textColor;
      @include flex(center, center);
      height: 100%;
      @include textStyle(0.6rem, bold);
      line-height: 1.2;
      z-index: 2;
      transition: all 0.3s;
      white-space: nowrap;

      &.inactive {
        color: var(--textColor);
        opacity: 0.5;
      }
    }
  }

  &_widget {
    height: 24px;
    //border-radius: 8px;
    width: 190px;
    min-width: 100px;
    @include applyWithTheme(
      background-color,
      var(--boxColor_2),
      var(--boxColor)
    );

    //.switchInner {
    //  .switchBtn {
    //    background: #f1f5f9;
    //    border-radius: 6px;
    //  }
    //
    //  .button {
    //    color: $textColor;
    //    @include textStyle(0.75rem, 600);
    //    background: #ffffff;
    //    border-radius: 6px;
    //
    //    &.inactive {
    //      color: var(--textColor);
    //      background: #f1f5f9;
    //    }
    //  }
    //}
  }
}

//For big screen
@media (min-width: $minWidthForBiggerContentSize+px) {
  .switch {
    width: 380px;
    min-width: 380px;
    height: 28px;

    .switchInner {
      .button {
        @include textStyle(0.75rem, bold);
        line-height: normal;
      }
    }
  }
}

//For big screen
@media #{$xsDOWN} {
  .switch {
    .switchInner {
      .button {
        font-size: 0.55rem;
      }
    }
  }
}
