@import 'src/sources/styles/mixin.scss';
@import 'src/sources/styles/variables.scss';

//Resize
%btnMainStyle {
  cursor: pointer;
  @include flex(center, center);
  box-shadow: 5px 10px 30px rgba(0, 0, 0, 0.05);
}

%btnClassicStyle {
  mix-blend-mode: normal;
  border-radius: 7px;
  outline: none;
  border: none;
}

.btn {
  @extend %btnMainStyle;
  @extend %btnClassicStyle;
  background-color: rgba(17, 38, 161, 0.1);
  transition: all 0.2s;
}

.btnText {
  @include textStyle(0.9rem);
  line-height: 1.2;
}

.btnIcon {
  margin-right: 13px;
}
.btnIconSvg {
  margin-right: 13px;
}

.btnBordered {
  @extend %btnMainStyle;
  height: 50px;
  width: 170px;
  outline: none;
  background-color: transparent;
  border: 1px solid var(--textColor);
  border-radius: 7px;
}

.classicDark {
  @extend %btnMainStyle;
  @extend %btnClassicStyle;
  background-color: rgba(104, 104, 104, 0.1);
}

.btnDisabled {
  background-color: rgba(17, 38, 161, 0.1);
  opacity: 0.3;
  cursor: default;
  mix-blend-mode: normal;
  border-radius: 7px;
  outline: none;
  border: none;
  @include flex(center, center);
  box-sizing: border-box;
  box-shadow: 5px 10px 30px rgba(0, 0, 0, 0.05);
  pointer-events: none;
}

//For big screen
@media (min-width: $minWidthForBiggerContentSize+px) {
  .btnText {
    @include textStyle(1.1rem);
  }
}

@media #{$smDOWN} {
  .btn {
    background-color: #e7e9f5 !important;
  }
  .btnBlueHover {
    background-color: rgba(17, 38, 161, 0.1) !important;
  }
}

@media (hover: hover) {
  .btnBordered:hover {
    background: $textColor;
    > .btnText {
      color: $white !important;
    }
    > .btnIconSvg {
      background: rgba(255, 255, 255, 1) !important;
    }
  }

  .btn:hover {
    background: $white !important;
    .textBtnHover {
      color: #1f45c3 !important;
    }
  }

  .btnBlueHover:hover {

    background: rgba(103, 174, 255, 0.6) !important;
    .textBtnHover {
      color: #1f45c3 !important;
    }
  }

  .classicDark:hover {
    background: $boxColorLight !important;
    > .btnText {
      color: $lightText !important;
    }
    > .btnIconSvg {
      background: $lightText !important;
    }
  }
}
