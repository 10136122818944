@import 'src/sources/styles/mixin.scss';
@import 'src/sources/styles/variables.scss';

.container {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  //Test
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 11px;
  overflow: hidden;

  .poster {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    z-index: 1;
    opacity: 1;
    visibility: visible;
    transition: visibility 0.5s linear, opacity 0.5s linear;
    border-radius: 11px;
    overflow: hidden;

    &.posterHidden {
      z-index: 0;
      opacity: 0;
      visibility: hidden;
    }
  }
}

.btn {
  z-index: 2;
  position: absolute;
  height: 80px;
  width: 80px;
  border-radius: 50%;
  background: #1128a3;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &.btnIconSmall {
    height: 48px;
    width: 48px;
  }

  &:hover {
    background: rgba(255, 255, 255, 1) !important;

    .btnIcon {
      background: rgba(17, 40, 163, 1) !important;
    }
  }
}

.reactPlayer {
  display: flex;

  > video {
    outline: none;
    border: none;
    max-height: 100%;
    border-radius: 11px;
    background: black;
    aspect-ratio: 16/9;
  }
}

.posterControls {
  cursor: pointer;
  @include flex(center, center);
  color: white;
  padding: 10px;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  right: 0;
  width: 40px;
  height: 40px;
  border-radius: 10px 0 0 10px;

  &:hover {
    background-color: rgba(0, 0, 0, 0.7);
  }

  svg {
    width: 18px;
    height: 18px;

    path {
      fill: white;
    }

    circle {
      fill: white;
    }

    stroke: white
  }

  .deleteIcon {
    height: 20px;
    width: 20px;
    stroke-width: 3px;
  }
}
