@import 'sources/styles/mixin.scss';
@import 'sources/styles/variables.scss';

.socialPostInfo {
  margin: 5px 0 10px;
  width: 100%;
  @include flex(flex-start, center);
  flex-direction: column;
  gap: 1rem;

  .link {
    width: 100%;
    @include flex(flex-start, center);
    flex-direction: column;

    .title {
      margin-bottom: 5px;
      width: 100%;
      @include flex(center, flex-start);
      @include textStyle(0.85rem, bold);
      color: var(--textColor);

      > span {
        color: red;
      }
    }

    .editorWrapper {
      width: 100%;
    }

    .buttons {
      width: 100%;
      padding-top: 0.5rem;

      .button {
        width: 100%;
        height: 50px;
        padding: 0.5rem;
      }
    }
  }
}

//For big screen
@media (min-width: $minWidthForBiggerContentSize+px) {
  .socialPostInfo {
    .link {
      .title {
        @include textStyle(1rem, bold);

        .charactersQuantity {
          @include textStyle(0.72rem);
        }
      }
    }
  }
}
