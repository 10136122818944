@import 'src/sources/styles/mixin.scss';
@import 'src/sources/styles/variables.scss';

.box {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  //box-shadow: 0 3px 10px rgba(0, 0, 0, 0.25);
  overflow: hidden;
  min-height: 250px;
}

model-viewer {
  width: 100%;
  height: 100%;
  //background-color: #70bcd1;
  //--poster-color: #ffffff00;
  border-radius: 11px;
  --poster-color: rgba(22, 20, 50, 0.1);
  //--progress-bar-color: red;
}
