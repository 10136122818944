@import 'src/sources/styles/mixin.scss';
@import 'src/sources/styles/variables.scss';

.menuItem {
  @include button();
  width: 100%;
  border-bottom: var(--borderBurgerMenu);
  height: 40px;
  display: flex;
  align-items: center;
  cursor: pointer;
  mix-blend-mode: normal;

  &:hover {
    border-radius: 6px;
    border-bottom: 1px solid transparent;
  }

  &:last-child {
    border-bottom: none;
  }

  .menuItemInner {
    @include textStyle(0.9rem);
    padding: 0 8px;
    color: var(--textColor);
    text-decoration: none;
    display: flex;
    align-items: center;
    flex-grow: 1;
    height: 100%;
  }
}
