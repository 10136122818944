@import 'sources/styles/mixin.scss';
@import 'sources/styles/variables.scss';

.fileWarningOverlay {
  border-radius: 11px;
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: background-color 0.15s;
  background-color: transparent;

  .text {
    @include textStyle(1rem, 700);
    visibility: hidden;
    color: white;
    text-align: center;
  }

  &_show {
    background-color: rgba(190, 0, 0, 0.75);
    border: 5px solid red;
    @include flex(center, center);
    padding: 0.5rem 0.25rem;
    z-index: 4;

    .text {
      visibility: visible;
    }
  }

  &_small {
    .text {
      @include textStyle(0.75rem, 700);
      padding-top: 0.5rem;
    }
  }
}
