@import 'src/sources/styles/mixin';
@import 'src/sources/styles/variables';

%stroke {
  stroke: var(--mainColor);
}

%fill {
  fill: var(--mainColor);
}

.authPrice {
  @include flex(center);
  color: var(--textColor);

  > svg {
    margin-right: 12px;
  }

  @include textStyle(0.9rem, 600);
  line-height: 1.2;
  padding: 8px 24px;
}

.icon {
  width: 24px;
  height: 24px;
  min-width: 24px;
  min-height: 24px;

  &.howItWorks {
    path {
      @extend %stroke;
    }
  }

  &.whitepaper {
    rect,
    path {
      @extend %stroke;
    }
  }

  &.authToken {
    > path {
      @extend %fill;
    }
  }

  &.marketplace {
    path:first-of-type {
      @extend %stroke;
    }

    path:last-of-type {
      @extend %fill;
    }
  }

  &.videoChat {
    path {
      @extend %stroke;
    }
  }

  &.invite {
    path {
      @extend %fill;
    }
  }

  &.FAQ {
    path {
      @extend %stroke;
    }

    circle {
      @extend %fill;
    }
  }

  &.logOut {
    path:first-of-type {
      @extend %fill;
    }

    path:last-of-type {
      @extend %stroke;
    }
  }
}
