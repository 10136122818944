@import 'src/sources/styles/mixin.scss';
@import 'src/sources/styles/variables.scss';

//Resize
.mediaBlock {
  margin-bottom: 8px;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  justify-content: flex-start;

  .mediaBlockItemNew {
    display: flex;
    width: 100%;

    .firstBlock {
      display: flex;
      flex-direction: column;
      -webkit-box-flex: 1;
      //flex-grow: 1;
      flex: 1 1 0px;
    }

    .firstBlockMarginRight {
      margin-right: 8px;
    }

    .secondBlock {
      display: flex;
      flex-direction: column;
      -webkit-box-flex: 1;
      //flex-grow: 1;
      flex: 1 1 0px;
    }
  }

  .mediaBlockItemNewMany {
    max-height: 330px;
  }

  .mediaBlockItem {
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 20px;
    background-position: center;
    overflow: hidden;
    width: fit-content;

    .image {
      min-height: 100%;
      max-width: 100%;
      max-height: 330px;
    }
  }

  .player {
    width: 100%;
    position: relative;
    border-radius: 20px;
    margin: 0;
    overflow: hidden;
    //aspect-ratio: 16/9;

    .editButtonBlockVideo {
      z-index: 5;
      position: absolute;
      top: 10px;
      right: 10px;
    }
  }

  .audioPlayerBox {
    width: 100%;
    position: relative;

    &_exceedsTwitterLimits {
      border: 5px solid red;
      border-radius: 11px;
      padding: 0.25rem;
    }
  }

  .audioPlayer {
    //padding: 15px 15px 30px;
    padding: 45px 15px 30px;
    position: relative;
    width: 100%;
    background: var(--boxColor);
    box-shadow: 0px 7px 25px var(--shadowColor);
    border-radius: 11px;
  }

  .videoContainer {
    position: relative;
  }

  .deleteButton {
    @include button(50%);
    @include center;
    width: 32px;
    height: 32px;
    cursor: pointer;
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 5;
    background: var(--mainColorRevert);

    &:hover {
      opacity: 0.5;
    }

    > svg {
      width: 20px;
      height: 20px;

      path {
        fill: var(--mainColor);
      }
    }

    &.deleteButtonAudio {
      top: 8px;
      left: 8px;
      width: 24px;
      height: 24px;
    }
  }

  .editButtonBlockAudio {
    height: 32px;
    min-height: 32px;
    margin-bottom: 15px;
  }
}

//For big screen
@media (min-width: $minWidthForBiggerContentSize+px) {
}
