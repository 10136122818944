@import 'sources/styles/mixin.scss';
@import 'sources/styles/variables.scss';

.socialPostPreview {
  $grayColorLight: rgb(83, 100, 113);
  $grayColorDark: rgb(112, 132, 151);

  $borderColorLight: #eeeeee;
  $borderColorDark: #bbbbbb;

  width: 100%;
  @include flex(flex-start, center);
  flex-direction: column;

  .frame {
    width: 100%;
  }

  .loaderBox {
    position: relative;
    @include flex(center, center);
    width: 70px;
    height: 70px;
    align-self: center;

    .loaderOuter {
      width: 100%;
      height: 100%;
      -webkit-animation: spin 1s linear infinite;
      -moz-animation: spin 1s linear infinite;
      animation: spin 1s linear infinite;
    }

    .loaderInner {
      position: absolute;
      width: 50%;
      height: 56%;
    }
  }

  .card {
    width: 100%;
    border: 1px solid $borderColorLight;
    border-radius: 12px;
    padding: 16px;
    font-family: Arial, sans-serif;
    background-color: #fff;
    text-decoration: none;
    font-family: -apple-system, 'system-ui', 'Segoe UI', Roboto, Helvetica,
      Arial, sans-serif;
    font-size: 15px;
    color: rgb(15, 20, 25);
    flex-direction: column;

    &:hover {
      background: rgb(247, 249, 249);
    }

    .header {
      display: flex;
      justify-content: space-between;
    }

    .profile {
      display: flex;
      align-items: center;
    }

    .avatar {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      margin-right: 0.5rem;
    }

    .name {
      @include flex(center);
      font-weight: bold;
      gap: 0.25rem;

      svg {
        width: 1.1rem;
        height: 1.1rem;

        transform: translateY(1px);

        path {
          fill: rgb(29, 155, 240);
        }
      }
    }

    .handle {
      color: $grayColorLight;
    }

    .follow {
      color: rgb(0, 111, 214);
      font-weight: bold;
      cursor: pointer;
    }

    .logo {
      svg {
        width: 30px;
        height: 30px;
      }
    }

    .replying {
      margin-top: 1rem;
      color: $grayColorLight;
    }

    .postText {
      margin-top: 0.5rem;
      font-size: 20px;
      font-weight: 400;
      line-height: 24px;
      overflow-wrap: break-word;
    }

    a {
      color: rgb(0, 111, 214);
      text-decoration: none;
      font-weight: 400;
    }

    .mediaGrid {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 2px;
      margin-top: 10px;
      clip-path: inset(0 0 0 0 round 10px);

      .media {
        width: 100%;
        height: 100%;
        object-fit: cover;

        &_wide {
          grid-column: span 2;
        }

        &.videoWrapper {
          position: relative;
          width: 100%;
          height: 100%;

          .video {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }

          .videoButton {
            border: 3px solid white;
            background: rgb(29, 155, 240);
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            position: absolute;
            width: 25%;
            border-radius: 50%;
            aspect-ratio: 1 /1;
            @include flex(center, center);
            max-width: 60px;

            svg {
              width: 50%;
              fill: white;
              transform: translateX(2px);
            }
          }
        }
      }
    }

    .embedLink {
      margin-top: 1rem;
      clip-path: inset(0 0 0 0 round 0.5rem);
    }

    .timestamp {
      color: $grayColorLight;
      font-size: 14px;
      margin-top: 8px;
      font-weight: 400;
      line-height: 20px;
      font-stretch: 100%;
    }

    .actions {
      margin-top: 0.5rem;
      border-top: 1px solid rgba(23, 25, 35, 0.063);
      padding: 0.75rem 0 0.25rem 0;

      @include flex(center, space-between);
      gap: 0.5rem;

      .actionsItem {
        @include flex(center);
        gap: 0.25rem;

        .actionsItemText {
          margin: 0;
        }
      }
    }

    .readMore {
      width: 100%;
      padding: 10px;
      margin-top: 10px;
      background-color: inherit;
      color: rgb(0, 111, 214);
      border-radius: 20px;
      cursor: pointer;
      font-size: 14px;
      border: 1px solid $borderColorLight;
      font-weight: 700;

      &:hover {
        background: rgba(0, 111, 214, 0.1);
      }
    }

    &_darkStyle {
      background: #15202b;
      color: #d7dbdc;
      border-color: $borderColorDark;

      &:hover {
        background: #1b2837;
      }

      .handle {
        color: $grayColorDark;
      }

      .logo {
        svg {
          fill: #d7dbdc;
        }
      }

      .replying {
        color: $grayColorDark;
      }

      .timestamp {
        color: $grayColorDark;
      }

      .readMore {
        border-color: $borderColorDark;
      }
    }
  }
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
