@import 'sources/styles/mixin.scss';
@import 'sources/styles/variables.scss';

//-▼-User image
.imageBlock {
  position: absolute;
  top: 24px;
  left: 12px;

  &.pwaMode {
    top: 13px;
    left: 0;

    > img {
      width: 32px;
      height: 32px;
    }
  }

  > img {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    object-fit: cover;
  }

  .emptyImage {
    background: #e9ecf9;
  }
}

//For big screen
@media (min-width: $minWidthForBiggerContentSize+px) {
  .imageBlock {
    top: 24px;
    left: 10px;

    > img {
      width: 42px;
      height: 42px;
    }
  }
}

@media #{$smDOWN} {
  .imageBlock {
    > img {
      width: 20px;
      height: 20px;
    }
  }
}
