@import 'src/sources/styles/mixin.scss';
@import 'src/sources/styles/variables.scss';


.modalFooter {
  @include flex(center);
  flex-direction: column;
  width: 100%;

  .buttonsBox {
    margin-bottom: 8px;
    padding-right: 3px;
    width: 100%;
    height: 36px;
    @include flex(center, space-between);
    background: var(--PWAAddMediaButtonsBG);
    border-radius: 32px;
  }
}



.editorAreaWrapper {
  height: 100%;
  flex-grow: 1;
  position: relative;
  display: flex;
  flex-direction: column;

  &.editorAreaWrapperFitContent {
    height: unset;
    flex-grow: unset;
  }

  .switchBox {
    @include flex(center, flex-end);
    width: 100%;
  }
}
