@import 'src/sources/styles/mixin.scss';
@import 'src/sources/styles/variables.scss';

.createPublicationButton {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  z-index: 500;
  padding: 1rem;

  &:hover {
    .iconNewPost {
      filter: invert(1);
    }
  }
}
