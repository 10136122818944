@import './variables.scss';
@import './mixin.scss';

html,
body,
#root {
  background-color: var(--backgroundColor);
  -webkit-tap-highlight-color: transparent;
  scrollbar-color: darkgrey slategrey;
  scrollbar-width: thin;

  ::-webkit-scrollbar {
    height: 3px;
    width: 3px;
  }

  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(46, 43, 99, 0.3);
    border-radius: 5px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: darkgrey;
    outline: 1px solid slategrey;
  }

  ::-webkit-scrollbar-button {
    display: none;
  }
}


//Audio Player
.rhap_container {
  border: 2px solid var(--writeBlockTextAreaBorder);
  //background: var(--profileBackColor1024);
  background: var(--boxColor);
}

.rhap_download-progress {
  opacity: 0.6;
}

.rhap_time {
  color: var(--textColor);
  @include textStyle(1rem)
}

.rhap_repeat-button {
  color: var(--textColor);
}
.rhap_main-controls-button svg {
  color: var(--textColor);
}
.rhap_progress-filled,
.rhap_progress-indicator {
  background-color: var(--textColor) !important;
}

.rhap_button-clear.rhap_volume-button {
  color:var(--textColor) !important;
}

.rhap_volume-bar, .rhap_volume-indicator {
  background-color: var(--textColor);
}
