@import 'sources/styles/mixin.scss';
@import 'sources/styles/variables.scss';

.container {
  .block {
    margin-top: 15px;
    width: 100%;
    @include flex(flex-start, center);
    flex-direction: column;

    .title {
      margin-bottom: 4px;
      width: 100%;
      padding-left: 10px;
      @include flex(center, flex-start);
      @include textStyle(1rem, bold);
      color: var(--textColor);

      >span {
        color: red;
      }

      .charactersQuantity {
        margin-left: auto;
        padding-right: 5px;
        @include textStyle(0.6rem)
      }

      .charactersQuantityError {
        color: red;
      }
    }

    .editorWrapper {
      width: 100%;
    }
  }
}

//For big screen
@media (min-width: $minWidthForBiggerContentSize+px) {
  .container {
    .block {
      .title {
        @include textStyle(1.2rem, bold);

        .charactersQuantity {
          @include textStyle(0.75rem)
        }
      }

    }
  }
}
