@import './variables.scss';

:root[data-theme='light'] {
  //Main colors
  --mainColor: #{$mainColorLight};
  --mainColorRevert: #{$mainColorRevertLight};
  --backgroundColor: #{$lightFon};
  --backgroundColorWhite: #{$white};
  --boxColor: #{$boxColorLight};
  --boxColorRevert: #{$boxColorLightRevert};
  --boxColor_2: #{$boxColorLight_2};
  --boxColor_3: #{$boxColorLight_3};
  --boxColor_4: #{$boxColorLight_4};
  --boxColor_5: #{$boxColorLight_5};
  --boxColor_6: #{$boxColorLight_6};
  --textColor: #{$lightText};
  --textColorRGB: #{$lightTextRGB};
  --shadowColor: #{$lightShadow};
  --hoverMenuItemColor: #{$hoverMenuItemColorLight};
  --likeButtonColor: #{$likeButton};
  --linkColor: #{$linkColor};
  //Background Images
  --backgroundImageBig: url(#{$backImage2kLight});
  --backgroundImageMedium: url(#{$backImageWithOutDonutsLight});
  --backgroundImageSmall: url(#{$backImageMobLight});
  //Page FAQ
  --colorItemActiveMenuFAQ: #{$colorItemActiveMenuFAQLight};
  //Burger Menu in the Header
  --backgroundBurgerMenu: #{$backBurgerMenuLight};
  --backgroundBoxBurgerMenu: #{$backBoxBurgerMenuLight};
  --borderBurgerMenu: #{$borderBurgerMenuLight};
  --linkHoverBurgerItem: #{$linkHoverBurgerItemLight};
  //Calendar
  --calendarButtonHoverFocus: #{$calendarButtonHoverFocusLight};
  --calendarButtonDisabled: #{$calendarButtonDisabledLight};
  --calendarTextDisabled: #{$calendarTextDisabledLight};
  //Live Feed
  --liveFeedBtnBack: #{$liveFeedBtnBackLight};
  --liveFeedBtnTextColor: #{$liveFeedBtnTextColorLight};
  //Text Error
  --textErrorColor: #{$textErrorColorLight};
  //Profile
  --profileBackColor1024: #{$profileBackColor1024Light};
  --socialBackColorInput: #{$socialBackColorInputLight};
  --twitterCheckBackColor: #{$twitterCheckBackColorLight};
  --tabPanelBackColor: #{$tabPanelBackColorLight};
  --tabPanelTabActive: #{$tabPanelTabActiveLight};
  --tabPanelMobTabActive: #{$tabPanelMobTabActiveLight};
  --writeBlockTextAreaBorder: #{$writeBlockTextAreaBorderLight};
  //Custom Input
  --inputBackColor: #{$inputBackColorLight};
  //Modal
  --modalTitleColor: #{$modalTitleColorLight};
  --modalButtonBorderTextColor: #{$modalButtonBorderTextColorLight};
  //Menu in the Header
  --menuSearchColor1400: #{$menuSearchColor1400Light};
  //Repost on hover box shadow
  --hoverRepostBoxShadow: #{$hoverRepostBoxShadowLight};
  //Bought post style
  --boughtPostBack: #{$boughtPostBackLight};
  //--boughtPostShadow: #{$boughtPostBackLight};
  //Input background disabled
  --inputBackDisabled: #{$inputBackDisabledLight};
  //Video chat
  --backgroundVideoChat: #{$backgroundVideoChatLight};
  //Encrypted post
  --backgroundEncrypted: #{$backgroundEncryptedLight};
  --backgroundEncryptedMention: #{$backgroundEncryptedMentionLight};
  --colorCanDecrypt: #{$colorCanDecryptLight};
  //Repost modal
  --backgroundOriginPost: #{$backgroundOriginPostLight};
  //Videos page
  --videosBackgroundInput: #{$videosBackgroundInputLight};
  --videosColorInput: #{$videosColorInputLight};
  --videosBackedBackground: #{$videosBackedBackgroundLight};
  //Mention
  --mentionBackgroundActive: #{$mentionBackgroundActiveLight};
  --mentionBackgroundFocused: #{$mentionBackgroundFocusedLight};
  --mentionBorderTop: #{$mentionBorderTopLight};
  //Superpost
  --superpostMediaIconFill: #{$superpostMediaIconFillLight};
  --superpostMediaIconShadow: #{$superpostMediaIconShadowLight};
  //File
  --fileBackground: #{$fileBackgroundLight};
  //Blogpost
  --blogpostPlusOpen: #{$blogpostPlusOpenLight};
  --blogpostBlockquoteBG: #{$blogpostBlockquoteBGLight};
  //Video short
  --videoShortMediaIconBG: #{$videoShortMediaIconBGLight};
  //Video stream
  --videoStreamMessageBG: #{$videoStreamMessageBGLight};
  --videoStreamCopyBoxBG: #{$videoStreamCopyBoxBGLight};
  --videoStreamCopyLinkColor: #{$videoStreamCopyLinkColorLight};
  --videoStreamCategoryBG: #{$videoStreamCategoryBGLight};

  //PWA
  //Footer
  --PWAFooterBG: #{$PWAFooterBGLight};
  --PWAEthereumIconColor: #{$PWAEthereumIconColorLight};
  --PWAAddMediaButtonsBG: #{$PWAAddMediaButtonsBGLight};
  //AudioVideoTitleDescription
  --PWAAVTDBorderColor: #{$PWAAVTDBorderColorLight};
  --PWAAVTDBackground: #{$PWAAVTDBackgroundLight};
  --PWAButtonBlue2Color: #{$PWAButtonBlue2ColorLight};
}

:root[data-theme='dark'] {
  //Main colors
  --mainColor: #{$mainColorDark};
  --mainColorRevert: #{$mainColorRevertDark};
  --backgroundColor: #{$darkFon};
  --backgroundColorWhite: #{$darkFon};
  --boxColor: #{$boxColorDark};
  --boxColorRevert: #{$boxColorDarkRevert};
  --boxColor_2: #{$boxColorDark_2};
  --boxColor_3: #{$boxColorDark_3};
  --boxColor_4: #{$boxColorDark_4};
  --boxColor_5: #{$boxColorDark_5};
  --boxColor_6: #{$boxColorDark_6};
  --textColor: #{$darkText};
  --textColorRGB: #{$darkTextRGB};
  --shadowColor: #{$darkShadow};
  --hoverMenuItemColor: #{$hoverMenuItemColorDark};
  --likeButtonColor: #{$likeButton};
  --linkColor: #{$linkColor};
  //Background Images
  --backgroundImageBig: url(#{$backImage2kDark});
  --backgroundImageMedium: url(#{$backImageWithOutDonutsDark});
  --backgroundImageSmall: url(#{$backImageWithOutDonutsDark});
  //Page FAQ
  --colorItemActiveMenuFAQ: #{$colorItemActiveMenuFAQDark};
  //Burger Menu in the Header
  --backgroundBurgerMenu: #{$backBurgerMenuDark};
  --backgroundBoxBurgerMenu: #{$backBoxBurgerMenuDark};
  --borderBurgerMenu: #{$borderBurgerMenuDark};
  --linkHoverBurgerItem: #{$linkHoverBurgerItemDark};
  //Calendar
  --calendarButtonHoverFocus: #{$calendarButtonHoverFocusDark};
  --calendarButtonDisabled: #{$calendarButtonDisabledDark};
  --calendarTextDisabled: #{$calendarTextDisabledDark};
  //Live Feed
  --liveFeedBtnBack: #{$liveFeedBtnBackDark};
  --liveFeedBtnTextColor: #{$liveFeedBtnTextColorDark};
  //Text Error
  --textErrorColor: #{$textErrorColorDark};
  //Profile
  --profileBackColor1024: #{$profileBackColor1024Dark};
  --socialBackColorInput: #{$socialBackColorInputDark};
  --twitterCheckBackColor: #{$twitterCheckBackColorDark};
  --tabPanelBackColor: #{$tabPanelBackColorDark};
  --tabPanelTabActive: #{$tabPanelTabActiveDark};
  --tabPanelMobTabActive: #{$tabPanelMobTabActiveDark};
  --writeBlockTextAreaBorder: #{$writeBlockTextAreaBorderDark};
  //Custom Input
  --inputBackColor: #{$inputBackColorDark};
  //Modal
  --modalTitleColor: #{$modalTitleColorDark};
  --modalButtonBorderTextColor: #{$modalButtonBorderTextColorDark};
  //Menu in the Header
  --menuSearchColor1400: #{$menuSearchColor1400Dark};
  //Repost on hover box shadow
  --hoverRepostBoxShadow: #{$hoverRepostBoxShadowDark};
  //Bought post style
  --boughtPostBack: #{$boughtPostBackDark};
  //--boughtPostShadow: #{$boughtPostBackLight};
  //Input background disabled
  --inputBackDisabled: #{$inputBackDisabledDark};
  //Video chat
  --backgroundVideoChat: #{$backgroundVideoChatDark};
  //Encrypted post
  --backgroundEncrypted: #{$backgroundEncryptedDark};
  --backgroundEncryptedMention: #{$backgroundEncryptedMentionDark};
  --colorCanDecrypt: #{$colorCanDecryptDark};
  //Repost modal
  --backgroundOriginPost: #{$backgroundOriginPostDark};
  //Videos page
  --videosBackgroundInput: #{$videosBackgroundInputDark};
  --videosColorInput: #{$videosColorInputDark};
  --videosBackedBackground: #{$videosBackedBackgroundDark};
  //Mention
  --mentionBackgroundActive: #{$mentionBackgroundActiveDark};
  --mentionBackgroundFocused: #{$mentionBackgroundFocusedDark};
  --mentionBorderTop: #{$mentionBorderTopDark};
  //Superpost
  --superpostMediaIconFill: #{$superpostMediaIconFillDark};
  --superpostMediaIconShadow: #{$superpostMediaIconShadowDark};
  //File
  --fileBackground: #{$fileBackgroundDark};
  //Blogpost
  --blogpostPlusOpen: #{$blogpostPlusOpenDark};
  --blogpostBlockquoteBG: #{$blogpostBlockquoteBGDark};
  //Video short
  --videoShortMediaIconBG: #{$videoShortMediaIconBGDark};
  //Video stream
  --videoStreamMessageBG: #{$videoStreamMessageBGDark};
  --videoStreamCopyBoxBG: #{$videoStreamCopyBoxBGDark};
  --videoStreamCopyLinkColor: #{$videoStreamCopyLinkColorDark};
  --videoStreamCategoryBG: #{$videoStreamCategoryBGDark};

  //PWA
  //Footer
  --PWAFooterBG: #{$PWAFooterBGDark};
  --PWAEthereumIconColor: #{$PWAEthereumIconColorDark};
  --PWAAddMediaButtonsBG: #{$PWAAddMediaButtonsBGDark};
  //AudioVideoTitleDescription
  --PWAAVTDBorderColor: #{$PWAAVTDBorderColorDark};
  --PWAAVTDBackground: #{$PWAAVTDBackgroundDark};
  --PWAButtonBlue2Color: #{$PWAButtonBlue2ColorDark};
}
