@import 'src/sources/styles/mixin.scss';
@import 'src/sources/styles/variables.scss';

.blockquote {
  border-left: 5px solid #4CA8DE;
  margin: 0 0 20px;
  padding: 10px 5px 10px 20px;
  color: var(--textColor);
  background: var(--blogpostBlockquoteBG);

  a {
    text-decoration: underline;
  }
}
