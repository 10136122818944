@import 'src/sources/styles/mixin.scss';
@import 'src/sources/styles/variables.scss';

.nftContainer {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #000;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  @include flex(center, space-between);
  flex-direction: column;
  font-family: 'SonySketch';
  font-weight: bold;

  .top {
    width: 100%;
    @include flex(center, flex-end);

    .priceBlock {
      padding: 15px;
      background: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
      background: -moz-linear-gradient(left, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
      background: -webkit-linear-gradient(left, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);

      .priceTitle {
        color: #FFE965;
        font-size: 24px;
        text-align: end;
      }

      .price {
        color: #51BCEC;
        font-size: 48px;
        text-align: end;
      }
    }
  }

  .bottom {
    @include flex(flex-end, space-between);
    width: 100%;

    .bottomLeftBlock {
      min-width: 210px;
      background: linear-gradient(to right, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
      background: -moz-linear-gradient(left, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
      background: -webkit-linear-gradient(left, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
      max-width: 50%;
      padding: 15px;

      .nftName {
        margin: 0 0 10px 0;
        font-size: 24px;
        font-weight: bold;
        color: #FFE965;
      }

      .nftDescription {
        color: #fff;
        font-size: 16px;
        line-height: 1rem;
      }

      .btnBlock {
        margin: 20px 0 0;
      }
    }

    .bottomRightBlock {
      //min-width: 210px;
      max-width: 50%;
      padding: 5px 15px 15px;
      background: -moz-linear-gradient(left, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
      background: -webkit-linear-gradient(left, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
      background: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
      text-align: end;
      font-size: 16px;

      .nft {
        font-size: 48px;
        color: #FFE965;
      }

      .nftCreator,
      .nftOwner {
        margin-top: 10px;
        color: #51BCEC;
      }

      .nftCreator div,
      .nftOwner div {
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 16px;
      }

      .nftOwner div:first-child,
      .nftCreator div:first-child {
        margin-bottom: 2px;
        color: #fff;
      }
    }
  }
}

@media #{$smDOWN} {
  .nftContainer {
    .bottom {
      flex-direction: column-reverse;

      .bottomRightBlock,
      .bottomLeftBlock {
        max-width: 100%;
        width: 100%;
      }

      .bottomRightBlock {
        padding: 0 15px 10px 15px;
        .nftCreator,
        .nftOwner {
          margin-top: 5px;
        }
      }
    }
  }
}
