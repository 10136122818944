@import 'src/sources/styles/mixin';
@import 'src/sources/styles/variables';

.userContainer {
  margin: 30px 24px 22px;

  .nameBox {
    margin-top: 12px;
    overflow: hidden;
    width: 100%;
    line-height: 1.2;

    .name {
      @include textStyle(0.9rem, bold);
      color: var(--textColor);
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .nickNameBlock {
      margin-top: 4px;
      width: 100%;
      @include flex(center, flex-start);

      .nickName {
        width: fit-content;
        @include nickNameText();
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}

