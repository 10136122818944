@import 'src/sources/styles/mixin.scss';
@import 'src/sources/styles/variables.scss';

//Resize

.createModalBlock {
  display: flex;
  flex-direction: column;
  margin: 0 0 32px 0;
  position: relative;

  &.createModalBlockFullSize {
    flex-grow: 1;

    .editorAreaWrapper {
      flex-grow: 1;
      display: flex;
    }
  }

  .symbolsQuantity {
    //position: absolute;
    width: auto;
    //height: 14.5px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 1rem;
    //top: -11px;
    right: 0;
    color: var(--textColor);
    @include textStyle(0.6rem);

    .blockQuantityPlus {
      display: flex;
      height: 14.5px;
      align-items: center;
      margin-right: 4px;
      justify-content: space-between;
      width: auto;

      .addBlockBtn {
        visibility: hidden;
        cursor: default;

        height: 14.5px;
        //cursor: pointer;
        z-index: 1000;

        &:hover {
          opacity: 0.7;
        }

        path {
          fill: var(--textColor);
        }

        circle {
          fill: var(--textColor);
        }
      }

      .addBlockBtnVisible {
        visibility: visible;
        cursor: pointer;
      }
    }

    //.blockQuantityPlusNotEmpty {
    //  width: 70.5px;
    //}

    .blockQuantityPlusMaxWidth {
      width: 100%;
    }
  }

  .symbolsQuantityMaxWidth {
    width: 100%;
  }

  .symbolsQuantityError {
    color: red;
  }

  //-▼-Text area block wrapper
  .editorAreaWrapper {
    position: relative;
  }
}

//-▼-Delete some block icon
.onDeleteIcon {
  width: 12px;
  height: 12px;
  cursor: pointer;
  stroke: darkgray;
  z-index: 1000;
}

.onDeleteIcon:hover {
  opacity: 0.5;
}

//-▼-Control buttons block wrapper
.controlBlock {
  display: flex;
  flex-direction: column;
  opacity: 0;
  height: 0;
  overflow: hidden;
  -moz-transition: opacity 0.2s, max-height 0.2s;
  -ms-transition: opacity 0.2s, max-height 0.2s;
  transition: opacity 0.2s, max-height 0.2s;
}

.activeControlBlock {
  //max-height: 139px;
  height: auto;
  opacity: 1;
}

//.activeControlBlockWithCheckbox {
//Check why need
//  max-height: 172px;
//}

//-▼-Additional buttons block wrapper
.additionalButtonsBlock {
  //min-height need for freepost
  min-height: 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  .getPriceBlock {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex: 1;
    padding: 0;
  }
}

//-▼-Create post button block
.createButtonBlock {
  margin: 20px 0 8px 0;

  .buttonsCheckboxes {
    @include flex(flex-start);
    flex-direction: column;
  }

  .buttonsBottom {
    width: 100%;
    @include flex(center);

    .fullSize {
      margin-left: 15px;
      width: 50px;
      min-width: 50px;
      height: 50px;
      @include center;
      @include button(7px);
      background: linear-gradient(131.69deg, #1f45c3 6.46%, #0e219b 100%);

      svg {
        width: 20px;
        height: 20px;

        > path {
          stroke: white;
        }

        > g {
          fill: white;
        }
      }

      &:hover {
        background: rgba(103, 174, 255, 0.6);

        svg {
          width: 20px;
          height: 20px;

          > path {
            stroke: #1f45c3;
          }

          > g {
            fill: #1f45c3;
          }
        }
      }
    }
  }

  .saveInDraft {
    width: 100%;
    color: var(--textColor);
    @include button();
    padding: 10px 65px 10px 0;
    margin-top: 10px;
    @include textStyle(0.9rem);

    &:hover {
      opacity: 0.6;
    }
  }
}

//-▼-Connected Lines with next block
.connectedLines {
  position: absolute;
  height: 32px;
  width: 1px;
  border: 1px solid rgba(169, 169, 169, 0.5);
  bottom: -32px;
  left: 50%;
}

//For big screen
@media (min-width: $minWidthForBiggerContentSize+px) {
  .createModalBlock {
    .symbolsQuantity {
      height: 17.5px;
      //top: -11px;
      font-size: 0.72rem;

      .blockQuantityPlus {
        height: 17.5px;

        .addBlockBtn {
          height: 17.5px;
        }
      }

      .blockQuantityPlusNotEmpty {
        width: 84.5px;
      }

      .blockQuantityPlusMaxWidth {
        width: 100%;
      }
    }

    .symbolsQuantityMaxWidth {
      width: 100%;
    }

    .symbolsQuantityError {
      color: red;
    }
  }

  .onDeleteIcon {
    margin-top: 1px;
    width: 14px;
    height: 14px;
  }

  .additionalButtonsBlock {
    min-height: 38px;
  }
}

@media #{$smDOWN} {
  .createButtonBlock {
    margin: 14px 0 8px 0;
  }

  .additionalButtonsBlock {
    //flex-wrap: wrap;

    .getPriceBlock {
      justify-content: space-between;
      margin-top: 12px;
      padding: 0;
    }
  }
}

@media #{$xsDOWN} {
  .additionalButtonsBlock {
    flex-direction: column;

    .getPriceBlock {
      justify-content: space-between;
      margin-top: 12px;
    }
  }
}

.connectTwitterBlock {
  display: flex;
  flex-direction: column;
  margin: 0 0 32px 0;
  position: relative;

  &-text {
    @include center;
    padding: 5px;
    @include textStyle(1rem);
    color: var(--textColor) !important;
  }
}
