@import 'src/sources/styles/mixin.scss';
@import 'src/sources/styles/variables.scss';


.urlEmbedInputBox {
  @include flex(center);
  height: 32px;
  width: 100%;
  background: var(--boxColor);

  .urlInput {
    padding: 0 0 0 3px;
    width: 100%;
    border: none;
    color: var(--textColor);
    @include textStyle(1rem);
    background: transparent;

    &::placeholder {
      color: var(--textColor);
      opacity: 0.48;
    }

    &:focus {
      outline: none;
    }
  }
}

//For big screen
@media (min-width: $minWidthForBiggerContentSize+px) {
  .urlEmbedInputBox {
    .urlInput {
      @include textStyle(1.2rem);
    }
  }
}
