@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap');

@font-face {
  font-family: SonySketch;
  src: url(./sources/fonts/Sony_Sketch_EF.ttf);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ffffff;
  display: flex;
  width: 100%;
  min-height: 100vh;
  font-size: 16px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
}

button {
  padding: 0;
}

html {
  scroll-behavior: smooth;
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}
